import React, { useState, useEffect } from "react";
import TabBar from "./TabBar";
import Table from "./Table";
import Loader from "../Loader";
import { useAppDispatch } from "../../redux/hooks";
import { getEnrolledPatientsAction } from "../../redux/actions/userActions";
import { Dietician } from "../../model/DieticianDataModel";
import { User } from "../../model/UserModel";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

type Status =
  | "Dietitian Evaluation"
  | "Enrolled Patient"
  | "Enrolled Dietitians";

interface PageData {
  page: number | 1;
  limit: number | 2;
}

const AdminDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Status>("Dietitian Evaluation");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enrolledDieticians, setEnrolledDieticians] = useState<Dietician[]>([]);
  const [allDieticians, setAllDieticians] = useState<Dietician[]>([]);
  const [enrolledPatients, setEnrolledPatients] = useState<User[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const dispatch = useAppDispatch();
  const token = localStorage.getItem("token");

  const handleTabClick = (tab: Status) => {
    setIsLoading(true);
    setCurrentPage(1);
    setTotalPages(0);
    setError(false);
    setActiveTab(tab);
  };

  useEffect(() => {
    getData(activeTab);
    setIsLoading(false);
  }, [activeTab, currentPage]);

  const getData = async (tab: string) => {
    if (!token) {
      return;
    }
    const pageData: PageData = { page: currentPage, limit: 10 };
    try {
      switch (tab) {
        case "Dietitian Evaluation":
          const dieticianConfig = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://backend.betternow.co.in/dietitian/admin/getAllDietitians?page=1&limit=100`,
            headers: {
              Authorization: token,
            },
          };

          axios
            .request(dieticianConfig)
            .then((response) => {
              console.log("All dietitians data", response.data.dietitians);
              setAllDieticians(response.data.dietitians);
              setTotalPages(response.data.totalPages);
            })
            .catch((error) => {
              console.log(error);
            });
          break;

        case "Enrolled Patient":
          const patients = await dispatch(getEnrolledPatientsAction(pageData));
          if (patients.meta.requestStatus === "fulfilled") {
            setTotalPages(patients.payload.totalPages);
            setEnrolledPatients(patients.payload.patients);
          }
          break;

        case "Enrolled Dietitians":
          const enrolledDieticianConfig = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://backend.betternow.co.in/dietitian/admin/getAllDietitians?isVerified=true&page=1&limit=100`,
            headers: {
              Authorization: token,
            },
          };

          axios
            .request(enrolledDieticianConfig)
            .then((response) => {
              console.log("All dietitians data", response.data.dietitians);
              setEnrolledDieticians(response.data.dietitians);
              setTotalPages(response.data.totalPages);
            })
            .catch((error) => {
              console.log(error);
            });
          break;

        default:
          break;
      }
    } catch (error) {
      setError(true);
      toast.error("An error occurred while fetching data.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="font-poppins">
      <Toaster position="top-center" reverseOrder={false} />
      {error && (
        <div className="alert alert-danger" role="alert">
          An error occuered fetching the dieticians!
        </div>
      )}
      <TabBar onTabClick={handleTabClick} activeTab={activeTab} />
      {isLoading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <Loader />
        </div>
      ) : (
        <>
          {activeTab === "Dietitian Evaluation" && (
            <Table
              data={allDieticians}
              background="white"
              type="Dietitian Evaluation"
              isDownloadable={false}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )}
          {activeTab === "Enrolled Patient" && (
            <Table
              users={enrolledPatients}
              background="white"
              type="Enrolled Patient"
              isDownloadable={true}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )}
          {activeTab === "Enrolled Dietitians" && (
            <Table
              dieticians={enrolledDieticians}
              background="white"
              type="Enrolled Dietitians"
              isDownloadable={false}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AdminDashboard;
