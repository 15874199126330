import React, { useState } from "react";
import SignupModal from "../Modals/SignupModal";

const SectionOne: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    
    <><div className="bg-[#315145] py-4 flex ">
      <div className="w-[50%] hidden lg:block">
        <img
          src="/group-01.png"
          className="w-full  hidden lg:block"
          alt="people" />
      </div>
      <div className=" w-full lg:w-[50%]">
        <div className="lg:py-10 px-8 flex flex-col justify-center items-center lg:items-start">
          <div className="text-[#FFFFF5] text-[30px] xs:text-[20px] lg:text-[50px] font-medium text-center lg:text-left w-[300px] lg:w-[418px]">
            Join our network who are earning more with{" "}
            <span className="text-[#FCCE72] font-extrabold text-[40px] xs:text-[25px] lg:text-[60px]">
              Betternow
            </span>
          </div>

          <div className="mt-4 lg:mt-10 flex justify-start">
            <button
              onClick={() => setIsOpen(true)}
              className="bg-[#6DC44A] w-fit text-white py-2 px-6 rounded-full text-[12px] lg:text-[24px] "
            >
              <div>Enroll Now</div>
            </button>
          </div>
        </div>
      </div>
    </div><SignupModal
        userRole="dietitian"
        setIsOpenModal={setIsOpen}
        isOpenModal={isOpen} />
        </>
  );
};

export default SectionOne;
