import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getDietitian } from "../../utils/DietitianApis";
import DefaultLayout from "../Layout/DefaultLayout";
import {
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import AppointmentsList from "../Profiles/AppointmentList";

const Appointment = () => {
  const [dietitianDetails, setDietitianDetails] = useState<any>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dietitianData = await getDietitian();
        if (dietitianData) {
          setDietitianDetails(dietitianData);
          console.log("d", dietitianDetails);
        }
      } catch (error) {
        console.error("Error fetching dietitian data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <DefaultLayout>
      <div className="p-8 bg-[#FFFFF5] xs:p-4">
        <div className="flex h-[92vh]">
          <div className="bg-[#EFEFEA] w-full rounded-lg p-4 text-[#315145] xs:p-[10px] ">
            <div className=" md:block">
              <div className="px-1 md:px-2 py-2 md:py-1">
                <div className="flex flex-row item-center text-center md:text-left text-[#315145] gap-[16px] items-center justify-between self-start">
                  <div className="text-[1.5rem] font-semibold md:text-[2.5rem] xs:text-[1.2em]">
                    Appointments
                  </div>
                  <div className="w-fit pt-1">
                    <div className="relative">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          color="#315145"
                        />
                      </div>
                      <input
                        type="search"
                        className="w-full p-2 ps-10 text-[12px] lg:text-[20px] text-[#315145] rounded-lg focus:outline-none"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </div>
              
                <AppointmentsList appointmentType="upcoming" data={dietitianDetails} isUserData={false} />
              <AppointmentsList appointmentType="past" data={dietitianDetails} isUserData={false} />
              {(!dietitianDetails?.upcomingAppointments?.length && !dietitianDetails?.pastAppointments?.length)?
              <div className="flex justify-center items-center h-full py-4">
              <p className="text-center text-lg font-semibold text-gray-500">No appointments</p>
              </div>
              :
              null
              }  
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Appointment;
