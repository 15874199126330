import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";
import SectionOne from "./SectionOne";
import { ExpertiseProp } from "../../utils/CommonInterface";
import SectionFive from "../Home/SectionFive";

const DieticianHome: React.FC<ExpertiseProp> = ({ expertise }) => {
  return (
    <div className="font-poppins">
      <Navbar
        backgroundColor="#FFFFF5"
        textColor="#325243"
        logo="/betterNowLogodark.png"
      />
      <SectionOne expertise={expertise} />
      <SectionFive/>
      <Footer />
    </div>
  );
};

export default DieticianHome;
