import React, { useEffect, useState } from "react";
import { LoginCredentials } from "../../model/CredentialPayloadModel";
import toast, { Toaster } from "react-hot-toast";
import { OTP_REQUIRED } from "../../constants/ErrorMessage";
import {
  PASSWORD_REQUIRED,
  USERNAME_REQUIRED,
} from "../../constants/ErrorMessage";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SignupModal from "../Modals/SignupModal";
import ForgotPasswordModal from "../Modals/ForgotPasswordModal";

interface Prop {
  setIsOpen: Function;
}
const LoginForm = ({ setIsOpen }: Prop) => {
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [showOTPform, setShowOTPform] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [loading, setLoading] = useState({
    type: "",
    value: false,
  });
  const [timeLeft, setTimeLeft] = useState(1 * 60); // 30 minutes in seconds
  const [formData, setFormData] = useState<LoginCredentials>({
    username: "",
    password: "",
  });
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [openForgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const navigate = useNavigate();
  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.username) newErrors.username = USERNAME_REQUIRED;
    if (!formData.password) newErrors.password = PASSWORD_REQUIRED;
    return newErrors;
  };

  useEffect(() => {
    let timer: any;
    if (isRunning) {
      timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft === 0) {
            clearInterval(timer);
            setIsRunning(false);
            return 0;
          } else {
            return prevTimeLeft - 1;
          }
        });
      }, 1000);
    }

    // Clean up the interval on unmount
    return () => clearInterval(timer);
  }, [isRunning]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const phonePattern = /^[2-9]{2}[0-9]{8}$/;
    if (name === "username") {
      if (value || value === "") {
        setFormData({ ...formData, [name]: value });
      }
    if(!value.match(phonePattern)){
      setErrors({...errors,[name]:'Enter a valid Phone number invalid'})
    } else {
      console.log("ok")
      setErrors({ ...errors, [name]: "" });
    }
    } else if (name === "password") {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleOTP = (emailOtp: string) => {
    const regex = /^\d+$/;
    if (regex.test(emailOtp) || emailOtp === "") {
      setOtpError(false);
      setEmailOtp(emailOtp);
    } else if (emailOtp !== "") {
      setOtpError(true);
      setOtpErrorMsg(OTP_REQUIRED);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newErrors = validate();
    console.log(newErrors);
    console.log(formData);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      const { ...payload } = formData;
      console.log("payload", payload);
      setLoading({ type: "login", value: true });

      const loginUser = () => {
        axios
          .post("https://backend.betternow.co.in/users/login", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response: any) => {
            setLoading({ type: "login", value: false });
            const token = response.data?.token;
            const userData = response.data?.userData;
            console.log("Userdata", userData);

            if (token && userData) {
              localStorage.setItem("token", token);
              const isEmailVerified = userData.isEmailVerified;
              const dietitianEnrollmentStatus =
                userData.dietitianEnrollmentStatus;

              if (!isEmailVerified) {
                setShowOTPform(true);
              } else {
                setIsOpen(false);
                if (userData.role === "dietitian") {
                  switch (dietitianEnrollmentStatus) {
                    case 0:
                      console.log("Not Filled");
                      navigate("/register");
                      break;
                    case 1:
                      console.log("Pending");
                      navigate("/enrollmentStatus", {
                        state: { status: dietitianEnrollmentStatus },
                      });
                      break;
                    case 2:
                      console.log("Approved");
                      navigate("/dashboard");
                      break;
                    case 3:
                      console.log("Rejected");
                      navigate("/enrollmentStatus", {
                        state: { status: dietitianEnrollmentStatus },
                      });
                      break;
                    default:
                      console.log("Sorry, something went wrong!");
                      toast.error("Sorry, something went wrong!");
                  }
                } else if (userData.role === "patient") {
                  // navigate("/patient-profile")
                  window.location.reload();
                } else {
                  navigate("/admin");
                }
              }
            } else {
              setLoading({ type: "login", value: false });
              toast.error("Sorry! Something went wrong.");
            }
          })
          .catch((error) => {
            setLoading({ type: "login", value: false });

            console.error(
              "Error:",
              error.response ? error.response.data : error.message,
            );
            toast.error(
              error.response ? error.response.data.message : error.message,
            );
          });
      };
      loginUser();
    } catch (error: any) {
      toast.error("An error occured. Please retry after sometime.");
      console.log("Error:", error);
    }
  };

  const handleOTPSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (emailOtp === "") {
      setOtpError(true);
      setOtpErrorMsg("OTP is required.");
      return;
    }
    if (showOTPform) {
      try {
        setEmailOtp(emailOtp);
        const verifyOtp = () => {
          const token = localStorage.getItem("token");
          axios
            .post(
              "https://backend.betternow.co.in/users/verify-email",
              { emailOtp: Number(emailOtp) },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
              },
            )
            .then((response) => {
              console.log("Response Data:", response.data);
              if (response.data.error) {
                setOtpError(true);
                setOtpErrorMsg(response.data.msg);
              } else if (!response.data.error) {
                if (response.data.userData.role === "dietitian") {
                  toast.success("Email verified succesfully");
                  navigate("/register");
                  console.log("OTP Verified Successfully");
                } else if (response.data.userData.role === "patient") {
                  toast.success("Email verified succesfully");
                  setLoading({ type: "", value: false });
                  setIsOpen(false);
                  console.log("OTP Verified Successfully");
                }
              }
            })
            .catch((error) => {
              console.error(
                "Error:",
                error.response ? error.response.data : error.message,
              );
              toast.error(
                error.response ? error.response.data.message : error.message,
              );
            });
        };
        verifyOtp();
      } catch (error: any) {
        toast.error("An error occured. Please retry after sometime.");
        console.log("Error:", error);
      }
    }
  };

  const resendOtp = async () => {
    try {
      const { ...payload } = formData;
      console.log("payload", payload);
      if (isRunning) {
        setIsRunning(false);
      }
      setLoading({ type: "resend_otp", value: true });

      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found.");
      }

      const response = await axios.post(
        "https://backend.betternow.co.in/users/resend-otp",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        },
      );
      console.log("Resend response", JSON.stringify(response.data));
      toast.success("OTP resent successfully.");
      setIsRunning(true);
    } catch (error: any) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message,
      );
      toast.error(error.response ? error.response.data.message : "Something went wrong");
    } finally {
      setLoading({ type: "", value: false });
    }
  };
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const handleSignUp =async()=>{
    setOpenSignUpModal(true);
  }
  const handleForgotPassword =async()=>{
    setForgotPasswordModal(true);
  }
  return (
    <>
      <section className="bg-[#EFEFEA] rounded-lg w-full py-6 justify-center flex">
        <Toaster position="top-center" reverseOrder={false} />
        <div className="px-4 lg:px-16 w-full">
          {!showOTPform && (
            <>
              <div className="mb-8  text-[20px] lg:text-[36px] font-bold text-center text-[#315145]">
                Welcome Back
              </div>
              <form action="#" className="space-y-6" onSubmit={handleSubmit}>
                <div className="relative">
                  <input
                    type="number"
                    className=" bg-[#F4F5F4] text-[12px] lg:text-[20px] rounded-lg w-full p-2.5 px-4 placeholder-[#797979] border-[3px] border-[#315145] focus:outline-none"
                    placeholder="Phone Number"
                    onChange={handleInputChange}
                    value={formData.username}
                    name="username"
                    required
                  />
                  {errors && (
                    <span className="absolute left-0 bottom-[-18px] text-red-500 text-sm">
                      {errors.username}
                    </span>
                  )}
                </div>
                <div className="relative">
                  <input
                    type="password"
                    className=" bg-[#EFEFEA] text-[12px] lg:text-[20px] rounded-lg w-full p-2.5 px-4 placeholder-[#797979] border-[3px] border-[#315145] focus:outline-none"
                    placeholder="Password"
                    onChange={handleInputChange}
                    id="password"
                    value={formData.password}
                    name="password"
                  />
                  {errors && (
                    <span className="absolute left-0 bottom-[-18px] text-red-500 text-sm">
                      {errors.password}
                    </span>
                  )}
                </div>

                <div className="flex items-center ">
                  <input
                    type="checkbox"
                    value=""
                    className="w-7 h-7 rounded-lg hover:cursor-pointer"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="text-[10px] lg:text-[16px]  text-[#3D3D3D] mx-4"
                  >
                    Keep me signed in
                  </label>
                </div>
                <div className="flex gap-[8px]">
                  {/* <div className="text-[10px] absolute top-[-14px] left-[100%] translate-x-[-100%] w-max">{"New user?"}</div> */}
                  <button
                    type="submit"
                    className="bg-[#315145] text-white py-2 rounded-lg text-[12px] lg:text-[24px] font-medium w-full"
                  >
                    {loading.type === "login" && loading.value
                      ? "Wait..."
                      : "Sign In"}
                  </button>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                    <span className="text-[14px] xs:text-[11px]">{"New user? "}</span>
                    <span
                      onClick={handleSignUp}
                      className="text-center text-underline py-2 rounded-lg text-[14px] w-full xs:text-[11px]"
                      style={{color:'blue', textDecoration:'underline'}}
                    >
                      {"Sign Up"}
                    </span>
                    </div>
                    <div>
                    <span
                      onClick={handleForgotPassword}
                      className="text-center text-underline py-2 rounded-lg text-[14px] w-full xs:text-[11px]"
                      style={{color:'blue', textDecoration:'underline'}}
                    >
                      {"Forgot Password"}
                    </span>
                    </div>
                  </div>
                  
              </form>
             
            </>
          )}
           
          {showOTPform && (
            <>
              <div className="mb-8  text-[20px] lg:text-[36px] font-bold text-center text-[#315145]">
              Verify Your Phone Number
              </div>
              <form onSubmit={handleOTPSubmit} className="space-y-6">
                <input
                  placeholder={`+91-${formData.username}`}
                  type="text"
                  name="phone"
                  value={""}
                  disabled
                  className={`bg-[transparent] border-[3px] border-[#315145] mb-0 text-[12px] lg:text-[20px] rounded-lg w-full p-2.5 px-4 placeholder-[#000000]`}
                />
                <div className="relative">
                  <input
                    placeholder="Enter OTP"
                    type="password"
                    maxLength={6}
                    name="emailOtp"
                    value={emailOtp}
                    onChange={(e: any) => handleOTP(e.target.value)}
                    className={`bg-[#EFEFEA] text-[12px] lg:text-[20px] rounded-lg w-full p-2.5 px-4 placeholder-[#797979] border-[3px] border-[#315145] focus:outline-none ${
                      otpError ? "border-red-500 border" : ""
                    }`}
                  />
                  {otpError && (
                    <div className="text-red-500 text-sm">{otpErrorMsg}</div>
                  )}
                </div>
                <button
                  type="submit"
                  className="bg-[#315145] text-white py-2 px-4 rounded-lg text-[12px] lg:text-[24px] font-medium w-full"
                >
                  {loading.type === "verify_otp" && loading.value
                    ? "Verifying"
                    : "Verify"}
                </button>
              </form>
              {!isRunning && (
                <div
                  onClick={resendOtp}
                  className="text-xs cursor-pointer pl-1 pt-1 mt-0 border"
                >
                  {loading.type === "resend_otp" && loading.value
                    ? "Sending..."
                    : "Resend OTP"}
                </div>
              )}
              {isRunning && (
                <div className="text-xs pl-1 pt-1 mt-0 border">
                  Resend in: {minutes}:{seconds < 30 ? `0${seconds}` : seconds}
                </div>
              )}
            </>
          )}
          {openSignUpModal &&
                 <SignupModal
                 userRole={"dietitian"}
                 setIsOpenModal={setIsOpen}
                 isOpenModal={openSignUpModal}
               />
                }
                {openForgotPasswordModal &&
                <ForgotPasswordModal
                setIsOpenForgotPasswordModal={setForgotPasswordModal}
                isOpenForgotPasswordModal={openForgotPasswordModal}
              />
                }
        </div>
      </section>
    </>
  );
};

export default LoginForm;
