import React, { useEffect, useState, useRef } from "react"; // Import useRef
import { ExpertiseProp } from "../../utils/CommonInterface";
import DieticianHomeText from "../../constants/DieticianHomeText";
import axios from "axios";
import toast from "react-hot-toast";
import DieticiancarouselMobile from "../Carousels/DeiticiancarouselMobile";
import DieticianCarousel from "../Carousels/DieticianCarousel";
import DropDown from "../Buttons/DropDown";
import "../Cards/Card.css";

const SectionOne: React.FC<ExpertiseProp> = ({ expertise }) => {
  let dieticianText;
  switch (expertise) {
    case "Diabetes":
      dieticianText = DieticianHomeText[0];
      break;
    case "PCOS":
      dieticianText = DieticianHomeText[1];
      break;
    case "Weight Management":
      dieticianText = DieticianHomeText[2];
      break;
    case "Gut Health":
      dieticianText = DieticianHomeText[3];
      break;
    case "Cholestrol":
      dieticianText = DieticianHomeText[4];
      break;
    default:
      dieticianText = DieticianHomeText[0];
      break;
  }

  const [dietitians, setDietitians] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [language, setLanguage] = useState<string>("");

  const dietitianCardsRef = useRef<HTMLDivElement>(null); // Create a ref for dietitian-cards

  useEffect(() => {
    console.log(
      "Fetching data for expertise:",
      expertise,
      "language:",
      language,
      "and page:",
      currentPage
    );
    const fetchData = async () => {
      try {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `https://backend.betternow.co.in/dietitian/getAllDietitians?healthCategory=${expertise}&language=${language}&page=${currentPage}&limit=3`,
        };

        const response = await axios.request(config);
        setDietitians(response.data.dietitians);
        setTotalPage(response.data.totalPages);
      } catch (error) {
        toast.error("Error fetching data.");
      }
    };

    fetchData();
  }, [expertise, currentPage, language]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    
    // Scroll to the dietitian-cards section smoothly
    if (dietitianCardsRef.current) {
      const top = dietitianCardsRef.current.getBoundingClientRect().top + window.scrollY; // Get the top position of the ref
      window.scrollTo({ top, behavior: "smooth" }); // Use window.scrollTo for smooth scrolling
    }
  };

  return (
    <>
      <div className="bg-[#FFFFF5] flex flex-col pb-14 md:flex-row justify-between px-10 py-8 md:px-0">
        <div className="px-4 md:px-14 ">
          <div className="text-[#000000] text-[1.875rem] md:text-[3vw] font-semibold text-left md:pt-20 ">
            <p>{dieticianText?.mainText}</p>
            <span className="text-[#315145]">{dieticianText?.spanText}</span>
          </div>
          <div className="md:px-0 text-[#000000] text-[0.9rem] md:text-[1.5vw] mt-2 md:mt-10 text-left">
            {dieticianText?.description}
          </div>
        </div>
        <img
          src={dieticianText.image || "/doctor.png"}
          className="h-full w-full lg:max-w-[600px] md:max-w-[480px] m-auto md:w-auto"
          alt="doctor"
        />
      </div>
      <div className="dietitian-cards" ref={dietitianCardsRef}> {/* Attach the ref here */}
        <div className="flex flex-row py-6 justify-center md:justify-start md:ml-[6vw]">
          <DropDown areaOfExpertise={expertise} setLanguage={setLanguage} />
        </div>
        {dietitians.map((dietitian, index) => (
          <div className="hidden md:block" key={index}>
            <DieticianCarousel expertise={dietitian} />
          </div>
        ))}
        {dietitians.map((dietitian, index) => (
          <div className="flex flex-col items-center block md:hidden" key={index}>
            <DieticiancarouselMobile expertise={dietitian} />
          </div>
        ))}
        <div className="justify-center flex z-100">
          {Array.from({ length: totalPage }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`mx-1 px-3 py-1 rounded-lg ${
                currentPage === index + 1
                  ? "bg-white text-[#325243]"
                  : "text-white"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default SectionOne;
