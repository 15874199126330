import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";
import SectionOne from "./SectionOne";
import { useLocation } from "react-router-dom";
interface BlogsProps {
  selectedTab?: string;
}
const Blogs: React.FC<BlogsProps> = () => {
  const location = useLocation();
  const selectedTab = location.state?.selectedTab || "All"; 
  return (
    <div className="font-poppins">
      <Navbar
        backgroundColor="#FFFFF5"
        textColor="#325243"
        logo="/betterNowLogodark.png"
      />
      <SectionOne tab={selectedTab}/>
      <Footer />
    </div>
  );
};

export default Blogs;
