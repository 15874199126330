import React, { useState } from "react";

interface AccordionProps {
  title: string;
  answer: string | JSX.Element;
  index: number;
}

const FAQAccordion: React.FC<AccordionProps> = ({ title, answer, index }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="px-2 py-3 md:py-[24px] md:px-4 bg-[#EFEFEA] rounded-[0.625rem] m-2 flex flex-col justify-center">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="flex items-baseline justify-between w-full text-left"
      >
        <div className="text-[#315145] font-medium text-[1rem] md:text-[18px] bg-white rounded-full w-6 h-6 flex justify-center items-center">
          {index + 1}
        </div>

        <span
          className={`flex-1 pl-2 mr-2 text-[#315145] font-medium text-[1rem] md:text-[18px] ${
            !accordionOpen ? "md:overflow-hidden md:whitespace-nowrap" : "font-semibold"
          }`}
        >
          {title}
        </span>

        <svg
          className={`transition-transform duration-300 ease-in-out ${accordionOpen ? "rotate-180" : ""}`}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          color="#355E3B"
        >
          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
        </svg>
      </button>
      
      <div
        className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
          accordionOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="pt-2 pl-[2.1em] text-[15px] md:text-[0.875rem] text-left text-[#7B7A7A]">
          {answer}
        </div>
      </div>
    </div>
  );
};

export default FAQAccordion;
