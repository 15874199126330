import React, { useState, useEffect } from "react";
import InputField from "./InputField";
import { formFields } from "../../constants/HealthFormFields";
import DefaultLayout from "../Layout/DefaultLayout";
import { jwtDecode, JwtPayload } from "jwt-decode";
import toast, { Toaster } from "react-hot-toast";

const PatientMedicalHistory: React.FC = () => {
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [patients, setPatients] = useState<{ label: string; value: string }[]>([]);
  const [patientMedicalHistory, setPatientMedicalHistory] = useState<any>([]);
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null); // State to track the selected patient
  const token = localStorage.getItem('token');
  const decoded: any = token ? jwtDecode<JwtPayload>(token) : null;
  const dietiticianId = decoded?.id;

  useEffect(() => {
    // Fetch enrolled patients from API
    const fetchPatients = async () => {
      try {
        const response = await fetch(
          `https://backend.betternow.co.in/dietitian/enrolledPatients/${dietiticianId}`,
          {
            method: "GET",
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setPatientMedicalHistory(data.data);
          const formattedPatients = data.data.map((patient: any) => ({
            label: `${patient.displayId} ${patient.name} ${patient.phone}`,
            value: patient._id,
          }));
          setPatients(formattedPatients);
        } else {
          console.error("Failed to fetch patients");
        }
      } catch (error) {
        console.error("Error fetching patients:", error);
      }
    };

    fetchPatients();
  }, [dietiticianId, token]);


  useEffect(() => {
    // Prefill form values based on selected patient
    if (selectedPatient) {
      const patientData = patientMedicalHistory.find((patient: any) => patient._id === selectedPatient);
      
      if (patientData) {
        console.log("Selected patient data:", patientData); // Log patient data to verify
        
        setFormValues({
          ...patientData, // Directly set the form values to the patient data
        });
      }
    }
  }, [selectedPatient, patientMedicalHistory]);
  

  const handleChange = (name: string, value: string | string[]) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" })); // Clear error on change
    console.log(formValues, "formValues");
  };

  const handlePatientChange = (patientId: string) => {
    setSelectedPatient(patientId);
    setFormValues((prev) => ({ ...prev, patient: patientId }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let formIsValid = true;
    const newErrors: { [key: string]: string } = {};

    formFields.forEach((field) => {
      if (field.required && !formValues[field.name]) {
        formIsValid = false;
        newErrors[field.name] = `${field.label} is required`;
      }
    });

    setErrors(newErrors);

    if (formIsValid) {
      try {
        const response = await fetch(
          'https://backend.betternow.co.in/dietitian/patientMedicalHistory',
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${token}`,
            },
            body: JSON.stringify({...formValues, patient: selectedPatient}),
          }
        );

        if (response.ok) {
          const data = await response.json();
          toast.success("Patient Medical History Saved Successfully");
          console.log('Form submitted successfully', data);

          // Refetch updated patient medical history
          const updatedHistoryResponse = await fetch(
            `https://backend.betternow.co.in/dietitian/enrolledPatients/${dietiticianId}`,
            {
              method: "GET",
              headers: {
                Authorization: `${token}`,
              },
            }
          );

          if (updatedHistoryResponse.ok) {
            const updatedData = await updatedHistoryResponse.json();
            setPatientMedicalHistory(updatedData.data);  // Update state with new medical history
            console.log('Patient medical history updated', updatedData.data);
          } else {
            console.error("Failed to fetch updated medical history");
          }
        } else {
          console.error('Failed to submit form');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    } else {
      console.log('Form has errors', newErrors);
    }
  };

  return (
    <DefaultLayout>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="font-poppins bg-[#FFFFF5] min-h-screen flex items-center justify-center">
        <form
          className="bg-white shadow-md rounded-lg p-6 w-full"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
            Patient Medical History
          </h2>
          <label className="block font-semibold mb-2 text-gray-700">
        {"Patient"} {<span className="text-red-500">*</span>}
      </label>
          <div className="mb-4">
          <select className="border rounded-lg p-2 w-full" onChange={(e) => handlePatientChange(e.target.value)} value={selectedPatient || ""}>
          <option value="" disabled>Select a patient</option>
          {patients.map((patient) => (
              <option key={patient.value} value={patient.value}>
                {patient.label}
              </option>
            ))}
       </select>
     </div>
          {formFields.map((field, index) => {
            const prefillValue = formValues[field.name] || ""; // Directly use formValues for prefilling

            return (
              <InputField
                key={index}
                label={field.label}
                inputType={field.inputType}
                name={field.name}
                value={prefillValue}
                onChange={handleChange}
                options={field.inputType === "searchable-dropdown" ? patients : field.options}
                required={field.required}
              />
            );
          })}
          <div className="flex justify-center">
            <button
              type="submit"
              className="mt-4 px-6 py-3 bg-teal-600 hover:bg-teal-700 text-white rounded-full focus:outline-none text-sm lg:text-base font-semibold shadow-lg transition duration-300"
            >
              Submit
            </button>
          </div>
          {Object.values(errors).map(
            (error, index) =>
              error && (
                <div key={index} className="text-red-500 text-sm mt-2 text-center">
                  {error}
                </div>
              )
          )}
        </form>
      </div>
    </DefaultLayout>
  );
};

export default PatientMedicalHistory;
