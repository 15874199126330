import React, { useState } from "react";
import Slider from "../Slider";
import SignupModal from "../Modals/SignupModal";

const SectionTwo: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("Per Year");
  const [jobType, setJobType] = useState("Full-time");
  const [sliderValue, setSliderValue] = useState(30); // Initial slider value
  const [isOpen, setIsOpen] = useState(false);
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleJobTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setJobType(event.target.value);
    setSliderValue(event.target.value === "Full-time" ? 30 : 10); // Reset slider value based on job type
  };

  const handleSliderChange = (value: number) => {
    setSliderValue(value);
  };

  const sliderValues = {
    fullTime: { min: 30, max: 50 },
    partTime: { min: 10, max: 30 },
  };

  const currentSliderValues =
    jobType === "Full-time" ? sliderValues.fullTime : sliderValues.partTime;

  const calculateEarnings = () => {
    const multiplier = jobType === "Full-time" ? 1000 : 800;
    if (selectedOption === "Per Year") {
      return multiplier * sliderValue * 52;
    } else {
      return multiplier * sliderValue * 4;
    }
  };

  return (
    <div className="bg-[#FFFFF5] pb-20 pt-10 lg:pt-4 flex flex-col justify-center items-center">
      <div className="text-[18px] lg:text-[40px] text-[#315145] mt-0 lg:mt-16">
        Estimated Earnings with Betternow
      </div>
      <div className="flex flex-col lg:flex-row bg-[#F3F3F3] mt-8 justify-around items-center py-4 lg:py-12 rounded-lg lg:w-[1064px]">
        <div className="flex flex-col items-center mb-4 lg:mb-0">
          <div className="text-[10px] lg:text-[20px] mb-2">I want to work:</div>
          <div className="flex flex-row justify-between ">
            <div className="mb-[0.125rem] me-4 inline-block min-h-[1.5rem] ps-[1.5rem]">
              <label className="mb-1 block">
                <input
                  type="radio"
                  name="jobType"
                  value="Full-time"
                  checked={jobType === "Full-time"}
                  onChange={handleJobTypeChange}
                />
                <span className="mx-2 text-[10px] lg:text-[20px]">
                  Full-time
                </span>
              </label>
            </div>
            <div className="mb-[0.125rem] me-4 inline-block min-h-[1.5rem] ps-[1.5rem]">
              <label className="mb-1 block">
                <input
                  type="radio"
                  name="jobType"
                  value="Part-time"
                  checked={jobType === "Part-time"}
                  onChange={handleJobTypeChange}
                />
                <span className="mx-2 text-[10px] lg:text-[20px]">
                  Part-time
                </span>
              </label>
            </div>
          </div>
          <div>
            <Slider
              min={currentSliderValues.min}
              max={currentSliderValues.max}
              value={sliderValue}
              onChange={handleSliderChange}
            />
          </div>
        </div>
        <div className="bg-[#FEFEFE] p-4 rounded-lg flex flex-col justify-center items-center">
          <div className="text-[10px] lg:text-[20px] mb-2">
            Estimated earnings
          </div>
          <div className="flex flex-row justify-between mb-2 items-center">
            <div className="text-[18px] lg:text-[36px] font-medium mx-2">
              Rs {calculateEarnings().toLocaleString('en-IN')}
            </div>
            <div className="text-[8px] lg:text-[16px] font-light">
              <div className="relative ">
                <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className="md:pr-5 block appearance-none border border-[#BCBCBC] hover:border-gray-500 rounded-lg px-2 focus:outline-none focus:shadow-outline"
                >
                  <option value="Per Year">Per Year</option>
                  <option value="Per Week">Per Month</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 lg:mt-10 flex justify-start">
        <button
          onClick={() => setIsOpen(true)}
          className="bg-[#A6DE9B] w-fit text-[#315145] py-2 px-6 rounded-full text-[12px] lg:text-[24px] "
        >
          <div>Get Started</div>
        </button>
      </div>
      <SignupModal
        userRole="dietitian"
        setIsOpenModal={setIsOpen}
        isOpenModal={isOpen}
      />
    </div>
  );
};

export default SectionTwo;
