import React from 'react';

interface Appointment {
  slotTime: string;
  dietitianName?: string;
  userName?: string;
}

interface AppointmentsListProps {
  appointmentType: 'past' | 'upcoming';
  data: { upcomingAppointments?: Appointment[]; pastAppointments?: Appointment[] };
  isUserData: boolean; // true if userData, false if dietitianData
}

const AppointmentsList: React.FC<AppointmentsListProps> = ({ appointmentType, data, isUserData }) => {
  const appointments = appointmentType === 'upcoming' ? data?.upcomingAppointments : data?.pastAppointments;

  if (!appointments || appointments.length === 0) {
    return null;
  }

  return (
    <>
      <h3 className="text-md md:text-lg font-semibold pt-4">
        {appointmentType === 'upcoming' ? 'Upcoming Appointments' : 'Past Appointments'}
      </h3>
      <div className="my-2 md:my-4">
        <div className="overflow-y-auto max-h-[30vh]">
          {appointments.map((appointment: Appointment, index: number) => {
            const appointmentDate = new Date(appointment.slotTime);
            const formattedDate = `${appointmentDate.getDate().toString().padStart(2, '0')}/${
              (appointmentDate.getMonth() + 1).toString().padStart(2, '0')}/${appointmentDate.getFullYear()}`;

            const formattedTime = appointmentDate.toLocaleString(undefined, {
              weekday: 'short',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            });

            // Determine the correct name field
            const name = isUserData ? appointment.dietitianName : appointment.userName;

            return (
              <div
                className="flex flex-row justify-center items-center text-center bg-white rounded-lg my-2 shadow-sm border border-gray-200 p-2 sm:pl-2"
                key={index}
              >
                <p className="w-1/4 text-[10px] sm:text-[13px] md:text-[16px] truncate text-center">
                  {name}
                </p>
                <p className="w-1/4 text-[10px] sm:text-[13px] md:text-[16px] truncate text-center overflow-hidden whitespace-nowrap">
                  {formattedDate}
                </p>
                <p className="w-1/4 text-[10px] sm:text-[13px] md:text-[16px] truncate text-center overflow-hidden whitespace-nowrap">
                  {formattedTime}
                </p>
                <div className="w-1/4 flex justify-center items-center text-center space-x-2 md:space-x-4">
                  <button onClick={() => window.open('https://wa.me/919870369693', '_blank')} className="bg-[#315145] text-white rounded-full text-[10px] md:text-[16px] px-5 py-2 xs:px-4">
                    Chat
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AppointmentsList;
