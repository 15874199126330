const Counter = ({ setMonth, month }: any) => {
  const increment = () => {
      if(month === 0){
        setMonth(2)
      } else {
        setMonth(month + 1);
      }
  };

  const decrement = () => {
    if (month > 2) setMonth(month - 1);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="p-5 pt-0">
        <div className="flex space-x-2 items-center">
          <button
            onClick={decrement}
            className=" text-[#AAAAAA] bg-[#D9D9D9] px-2 py-0 rounded flex w-1 md:w-auto md:h-auto h-4 items-center justify-center "
          >
            -
          </button>
          <h1 className=" text-[10px] md:text-xs font-medium text-[#505050] ">
            {month} month
          </h1>
          <button
            onClick={increment}
            className="text-[#AAAAAA] bg-[#D9D9D9] px-2 py-0 rounded flex w-1 md:w-auto md:h-auto h-4 items-center justify-center "
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default Counter;
