import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";
import SectionOne from "./SectionOne";
import SectionThree from "./SectionThree";
import SectionFive from "./SectionFive";
import SectionThreeNew from "./SectionThreeNew";

const Home: React.FC = () => {
  return (
    <div className="font-poppins">
      <Navbar
        backgroundColor="#315145"
        textColor="#FFFFF5"
        logo="/betternowLogo.png"
      />
      <SectionOne />
      <SectionThree />
      <SectionThreeNew />
      <SectionFive />
      <Footer />
    </div>
  );
};

export default Home;
