const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  return (
    <div className="sticky top-0 lg:hidden z-[100]">
    <header className="sticky top-0 z-[100] flex w-full bg-[#315145] lg:bg-[#FFFFF5] drop-shadow-1 ">
      <div className="flex flex-grow h-[5vh] items-center justify-between  px-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex items-center gap-2 sm:gap-4 lg:hidden ">
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }}
            className="z-99999 block p-1.5  lg:hidden "
          >
            <svg
              className="w-4 h-5"
              aria-hidden="true"
              fill="none"
              viewBox="0 0 17 14"
              style={{ color: "#FFFFF5" }}
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
      </div>
    </header>
    </div>

  );
};

export default Header;
