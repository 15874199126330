import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { userRoleSelector } from "../../redux/selectors/userSelector";
import {
  DieticianDashboardOptions,
  UserDashboardOptions,
  roles,
} from "../../constants/EnumValues";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const role = useSelector(userRoleSelector);
  const options =
    role === roles[0] ? UserDashboardOptions : DieticianDashboardOptions;
  const navigate = useNavigate();
  const location = useLocation(); // Tracks the current location
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  const handleLogout = async () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  const isActive = (path: string) => location.pathname === path; // Check if current path matches the option's path

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-[1] flex h-screen lg:w-[300px] flex-col overflow-y-hidden bg-[#315145] duration-300 ease-linear lg:static lg:translate-x-0 ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="flex items-center justify-center gap-2 px-2 py-5.5 lg:py-6.5 mt-8">
        <img
          src="/betterlogooffwhite.png"
          alt="Logo"
          className="w-[120px] h-[33px] lg:w-[200px] lg:h-[54.89px]"
        />

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden"
        >
        </button>
      </div>

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        <nav className="mt-5 py-4  lg:mt-9 px-0.5">
          <div>
            <ul className="mb-6 flex flex-col gap-1.5">
              {options.map((opt) => (
                <li key={opt.val}>
                  <NavLink
                    to={opt.to}
                    className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 text-lg duration-300 ease-in-out hover:bg-[#FFFFF5] hover:text-[#315145] ${
                      isActive(opt.to)
                        ? "bg-[#FFFFF5] text-[#315145] "
                        : "text-[#FFFFF5]"
                    }`}
                  >
                    <FontAwesomeIcon icon={opt.icon} />
                    {opt.optionName}
                  </NavLink>
                </li>
              ))}
            </ul>
            <div
              className="ml-10 flex absolute bottom-10 left-8 text-[20px] text-white cursor-pointer justify-center items-center"
              onClick={handleLogout}
            >
              <img className="p-1 w-6" src="/exit1.png" alt="exit"/>
              Logout
            </div>
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
