import React from "react";

interface SliderProps {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
}

const Slider: React.FC<SliderProps> = ({ min, max, value, onChange }) => {
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value));
  };

  return (
    <div className="relative w-[150px] lg:w-[450px] mx-auto mt-4 lg:mt-8">
      <div className="relative">
        <input
          className="rounded-lg overflow-hidden appearance-none bg-[#D9D9D9] h-4 w-full"
          type="range"
          min={min}
          max={max}
          step="1"
          value={value}
          onChange={handleSliderChange}
        />
        <div
          className="absolute text-center text-gray-700"
          style={{
            left: `calc(${((value - min) / (max - min)) * 100}% - 12px)`,
            top: "-24px",
          }}
        >
          {value}
        </div>
      </div>
      <div className="flex justify-between mb-2 text-[10px] lg:text-[14px]">
        <span>{min}</span>
        <span>Hour per week</span>
        <span>{max}</span>
      </div>
    </div>
  );
};

export default Slider;
