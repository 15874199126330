import { useNavigate } from "react-router-dom";
import AppWrapper from "../AppWrapper";
import { useEffect, useState } from "react";
import axios from "axios";

const EnrollmentStatus = () => {
  const navigate = useNavigate();
  const [status,setStatus] = useState()
  
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return;
        }
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: "https://backend.betternow.co.in/users/auth-status",
          headers: {
            Authorization: token,
          },
        };
        const response = await axios.request(config);
        if (
          response.data.data.role === "dietitian" &&
          response.data.data.isEmailVerified &&
          (response.data.data.dietitianEnrollmentStatus === 1 ||
            response.data.data.dietitianEnrollmentStatus === 3)
        ) {
          setStatus(response.data.data.dietitianEnrollmentStatus);
        }
      } catch (error) {
        console.log(error);
      }
    };

    checkAuthStatus();
    if (status === 2) {
      navigate("/dashboard");
    }
  }, []); 
  return (
    <AppWrapper>
      <div className="flex items-center justify-center w-full font-poppins ">
        <div className="w-[85%] md:w-[80%] bg-[#EFEFEA] shadow-xl p-[2rem] rounded-xl flex flex-col gap-y-2 md:gap-y-4 lg:gap-y-10">
          <div className="font-medium text-[18px] lg:text-[25px] text-[#355E3B]">
            <text>
              {status === 1
                ? `Thanks for signing up! 🎉 We're thrilled to have you on board! Our team is already reviewing your profile, and you'll hear from us soon. Stay tuned!`
                : `After careful review, we regret to inform you that your profile has not been accepted at this time. We appreciate your understanding and encourage you to stay in touch for future opportunities.`}
            </text>
          </div>
        </div>
      </div>
    </AppWrapper>
  );
};

export default EnrollmentStatus;
