import { useEffect } from "react";
import { createPortal } from "react-dom";
import ForgotPasswordForm from "../Forms/ForgotPasswordForm";
interface ForgotPasswordModalProps {
  isOpenForgotPasswordModal: boolean;
  setIsOpenForgotPasswordModal: Function;
}
function ForgotPasswordModal({
  isOpenForgotPasswordModal,
  setIsOpenForgotPasswordModal,
}: ForgotPasswordModalProps) {
  useEffect(() => {
    if (document) {
      document.body.style.overflowY = isOpenForgotPasswordModal ? "hidden" : "scroll";
    }
  }, [isOpenForgotPasswordModal]);
  if (!isOpenForgotPasswordModal) return null;
  return createPortal(
    <div className="z-50 fixed top-0 left-0 w-full h-full flex justify-center items-center overflow-auto bg-modal-container">
      <div
        onClick={() => setIsOpenForgotPasswordModal(false)}
        className="fixed w-full h-full bg-modal-container z-10"
      ></div>
      <div className="bg-[#FFFFF5] rounded-lg relative z-20 mx-5 w-[500px]">
        <div className="p-1 w-full flex justify-center">
          <ForgotPasswordForm setIsOpen={setIsOpenForgotPasswordModal} />
        </div>
        <button
          onClick={() => setIsOpenForgotPasswordModal(false)}
          className="z-50 absolute top-2 text-black text-xl cursor-pointer hover:text-red-500 right-5"
        >
          x
        </button>
      </div>
    </div>,
    document.getElementById("login-portal")!,
  );
}

export default ForgotPasswordModal;
