import {
  faWhatsapp,
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const footerItems = [
  { text: "FAQ", path: "/faq" },
  { text: "Privacy Policy", path: "/privacy-policy" },
  { text: "Contact Us", path: "/contact" },
  { text: "Terms & Condition", path: "/terms-of-services" },
  { text: "Refund Policy", path: "/refund-policy" },
];

const socialhandles = [
  {
    name: "whatsapp",
    icon: faWhatsapp,
    redirectionLink: "https://wa.me/919870369693",
  },
  {
    name: "facebook",
    icon: faFacebookF,
    redirectionLink: "https://www.facebook.com/betterdietnow",
  },
  {
    name: "instagram",
    icon: faInstagram,
    redirectionLink: "https://www.instagram.com/betterdietnow",
  },
];

const Footer: React.FC = () => {
  return (
    <footer className="bg-[#F5F0EC] p-8 md:p-16 flex xs:flex-col lg:flex-row justify-between items-center">
      <div className="flex flex-col w-3/5 mb-4 md:gap-y-10 items-center md:items-start">
        <img
          src="/logo-footer.png"
          className="xs:w-[90px] xs:h-[90px] h-[120px] w-[120px] lg:w-[11vw] lg:h-[11vw] "
          alt="Betternow-logo"
        />
        <div className="flex-row space-x-3 hidden lg:block ml-[-20px]">
          {socialhandles.map((item, index) => (
            <a key={index} href={item.redirectionLink} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon
                key={index}
                icon={item.icon}
                className="rounded-full w-[20px] h-[20px] bg-[#315145] p-2"
                color="white"
              />
            </a>
          ))}
        </div>
      </div>
      <div className="w-[100%] sm:w-[60vw] flex flex-col justify-center space-y-4 lg:space-y-8 text-[12px] lg:text-[14px]">
        <div className="grid grid-cols-2 gap-2 lg:gap-8 font-medium">
          {footerItems.map((item, index) => (
            <Link key={index} to={item.path}>
              {item.text}
            </Link>
          ))}
        </div>
        <div className=" flex-row space-x-2 flex lg:hidden item-center justify-center">
          {socialhandles.map((item, index) => (
            <a key={index} href={item.redirectionLink} target="_blank" rel="noopener noreferrer">

            <FontAwesomeIcon
              key={index}
              icon={item.icon}
              className="rounded-full bg-[#315145] p-2"
              color="white"
              to={item.redirectionLink}

            />
            </a>
          ))}
        </div>
        <div className="text-center lg:text-left text-[10px] lg:text-[14px]">
          © 2024 betternow. All rights reserved. Our website services and
          content are for informational purposes only. betternow does not
          endorse or provide any medical advice, diagnosis services, or
          treatment.{" "}
          <Link to="/privacy-policy" className="text-[red]">
            Please refer to privacy policy link.
          </Link>
        </div>
        <div className="px-2 text-center lg:text-left"> ♥ Made in India</div>
      </div>
    </footer>
  );
};

export default Footer;
