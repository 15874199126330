import axios from "axios";
import { jwtDecode, JwtPayload as OriginalJwtPayload } from "jwt-decode";

export interface JwtPayload extends OriginalJwtPayload {
  id: string;
  role: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  dietitianEnrollmentStatus: number;
}

export const getDietitian = async (): Promise<any> => {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode<JwtPayload>(token);
      const response = await axios.get(
        `https://backend.betternow.co.in/dietitian/${decoded.id}`,
        {
          maxBodyLength: Infinity,
        }
      );
      console.log("Myasas", response.data.data);
      return response.data.data;
    }
  } catch (error) {
    console.log("Dietitian Error", error);
    throw error;
  }
};
