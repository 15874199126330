import React from "react";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import "./Card.css";

interface DieticianDetailsCardProps {
  imageUrl: string;
  title: string;
  description: string;
  language: string[];
  yearOfExperience: number;
  id: string;
  clients: number;
  oneTimeFee: number;
  monthlyFee: number;
  userId: any;
  areaOfExpertise: any;
  gender: any;
  displayId: string;
}

const DieticianDetailsCard: React.FC<DieticianDetailsCardProps> = ({
  imageUrl,
  title,
  description,
  language,
  yearOfExperience,
  id,
  clients,
  oneTimeFee,
  monthlyFee,
  userId,
  areaOfExpertise,
  gender,
  displayId,
}) => {
  const navigate = useNavigate();
  
  
  const redirectToProfile = (userId: string, displayId: string) => {
    navigate(`/dietitian-profile/${displayId.toLowerCase()}`, { state: { userId, displayId: displayId.toLowerCase() } });
  };  

  const formatLanguages = (languages: string[]) => {
    if (languages.length === 0) return "No languages listed";
    if (languages.length === 1) return `Fluent in ${languages[0]}`;
    const lastLanguage = languages[languages.length - 1]; // Access the last language without modifying the array
    return `Fluent in ${languages.slice(0, -1).join(", ")} and ${lastLanguage}`;
  };
  

  return (
    <div>
      <div onClick={() => redirectToProfile(userId, displayId)} className="card-container">
        <div className="highlights-container">
          <div className="highlights">
            <div className="highlight-icon">
              <img className="w-3" src="/star1.png" alt="star" />
            </div>
            <div>Top-Rated</div>
          </div>
          <div className="highlights">
            <div className="highlight-icon">
              <img className="w-3" src="/heartnew.svg" alt=""/>
            </div>
            <div>{clients} happy client sessions</div>
          </div>
          <div className="highlights">
            <div className="highlight-icon">
              <img className="w-[12px] h-[12px]" src="/betternow.svg" alt="betternow" />
            </div>
            <div>Betternow Certified</div>
          </div>
        </div>
        <div className="card-details">
          <div className="profile-flex">
            <div className="profile">
              <img src={imageUrl} alt={title} />
            </div>
            <div className="dietitian-details">
              <h2>{gender === "Male" ? "Mr " : "Ms "}{title}</h2>
              <h3>
                {areaOfExpertise
                  ?.map((l: any) => l)
                  .join(", ")
                  .replace(/, ([^,]*)$/, " and $1")} Expert &nbsp; | &nbsp; {yearOfExperience} years of experience
              </h3>
              <h3>{formatLanguages(language)}</h3>
              <h4>{description.split(' ').slice(0, 30).join(' ') + (description.split(' ').length > 30 ? '...' : '')}</h4>
            </div>
          </div>
          <div className="book-appoint">
            <button onClick={(event: any) => {
              event.stopPropagation();
              navigate(`/checkout/${displayId.toLowerCase()}`, { state: { userId, displayId: displayId.toLowerCase() } });
            }} className="grab-spot">
              Grab your spot
            </button>
            <div className="text-[#ABABA7] text-[0.75rem] lg:text-[0.75vw] text-center mt-1 flex justify-center items-center">
              <span>•</span>
              <img className="w-[0.75rem] h-[0.75rem] mx-0.5" src="../chat.png" alt="chat" />
              Instant Confirmation
            </div>
            <div className="text-[#ABABA7] text-[0.75rem] lg:text-[0.75vw] text-center mt-1 flex justify-center items-center">
              <span>•</span>
              <img className="w-[0.75rem] h-[0.75rem] mx-0.5" src="../gmeet.png" alt="google meet" />
              Google Meet
            </div>
            <div className="consultation">
              One time consultation: ₹{oneTimeFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
            <div className="consultation">
              Premium Plan: ₹{monthlyFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => redirectToProfile(userId, displayId)} className="card-container-mobile">
        <div className="highlights-container">
          <div className="highlights">
            <div className="highlight-icon">
              <img className="w-3" src="/star1.png" alt="star" />
            </div>
            <div>Top-Rated</div>
          </div>
          <div className="highlights">
            <div className="highlight-icon">
              <img className="w-3" src="/heartnew.svg" alt=""/>
            </div>
            <div>{clients} happy client sessions</div>
          </div>
          <div className="highlights">
            <div className="highlight-icon">
              <img className="w-[12px] h-[12px]" src="/betternow.svg" alt="betternow" />
            </div>
            <div>Betternow Certified</div>
          </div>
        </div>
        <div className="card-details">
          <div className="profile-flex">
            <div className="profile">
              <img src={imageUrl} alt={title} />
            </div>
            <div className="dietitian-details">
              <h2>{gender === "Male" ? "Mr " : "Ms "}{title}</h2>
              <h3>
                {areaOfExpertise
                  ?.map((l: any) => l)
                  .join(", ")
                  .replace(/, ([^,]*)$/, " and $1")} Expert
              </h3>
              <h3>{yearOfExperience} years of experience</h3>
              <h3>{formatLanguages(language)}</h3>
            </div>
          </div>
          <h4>{description.split(' ').slice(0, 30).join(' ') + (description.split(' ').length > 30 ? '...' : '')}</h4>
          <div className="flex justify-between w-[85%] max-w-[250px] m-auto mt-[12px]">
            <div className="consultation">
              One time <br />
              consultation:<br />
              ₹{oneTimeFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
            <div className="consultation">
              Premium <br />
              Plan: <br /> ₹{monthlyFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
          <div className="book-appoint">
            <button onClick={(event: any) => {
              event.stopPropagation();
              navigate(`/checkout/${displayId.toLowerCase()}`, { state: { userId, displayId: displayId.toLowerCase() } });
            }} className="grab-spot">
              Grab your spot
            </button>
          </div>
          <div className="flex justify-between w-[85%] m-auto">
            <div className="text-[#ABABA7] text-[9px] text-center mt-1 flex justify-center items-center">
              <span>•</span>
              <img className="w-[0.75rem] h-[0.75rem] mx-0.5" src="../gmeet.png" alt="google meet" />
              Google Meet
            </div>
            <div className="text-[#ABABA7] text-[9px] text-center mt-1 flex justify-center items-center">
              <span>•</span>
              <img className="w-[0.75rem] h-[0.75rem] mx-0.5" src="../chat.png" alt="chat" />
              Instant Confirmation
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DieticianDetailsCard;
