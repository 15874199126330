import axios from "axios";

export const approveDietician = async (id: string, role: string) => {

  if (role !== "dietitian-admin") {
    throw new Error("Only admin has access to this functionality.");
  } else {
    try {
      const token = localStorage.getItem("token");
      const data = JSON.stringify({
        dietitianEnrollmentStatus: 2, // Assuming 2 means approved
      });

      const config = {
        method: "put",
        url: `https://backend.betternow.co.in/users/status/${id}`, // Dynamic ID
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error: any) {
      console.error("Error approving the dietician:", error.message);
      throw new Error("Error approving the dietician.");
    }
  }
};
