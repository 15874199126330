import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home";
import DieticianHome from "./components/DieticianHome";
import FAQ from "./components/FAQ";
import ContactUs from "./components/ContactUs";
import Enrollment from "./components/Enrollment";
import Dashboard from "./components/Dashboard/Dashboard";
import Profile from "./components/Dashboard/Profile";
import JoinUs from "./components/JoinUs";
import AdminDashboard from "./components/AdminDashboard";
import PatientMedicalHistory from "./components/Dashboard/PatientMedicalHistory";
import Checkout from "./pages/CheckoutPage/CheckoutPage";
import Appointment from "./components/Dashboard/Appointment";
import Terms from "./pages/Terms of Services/terms";
import Privacy from "./pages/Privacy Policy/Privacy";
import Refund from "./pages/Refund Policy/Refund";
import DoctorProfile from "./components/DoctorProfile";
import { useLayoutEffect } from "react";
import EditProfile from "./components/Dashboard/EditProfile";
import PatientProfile from "./components/Profiles/PatientProfile";
import EnrollmentStatus from "./components/EnrollmentStatus/EnrollmentStatus";
import PatientProtectedRoute from "./utils/PatientProtectedRoute";
import AdminProtectedRoute from "./utils/AdminProtectedRoute";
import DietitianProtectedRoute from "./utils/DietitianProtectedRoute";
import PendingDietitianProtectedRoute from "./utils/PendingDietitianProtectedRoute";
import NotFilledDietitianProtectedRoute from "./utils/NotFilledDietitianProtectedRoute";
import Settings from "./components/Dashboard/Settings";
import Congo from "./pages/CheckoutPage/Congo";
import ChatApp from "./components/Dashboard/Chat3";
import { HelmetProvider, Helmet } from "react-helmet-async";
import Blogs from "./components/Blog";
import BlogDetail from "./components/Blog/BlogDetail";

function App() {
  const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return <>{children}</>;
  };

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Helmet>
                    <title>Home | Betternow</title>
                    <meta name="description" content="Home page description for SEO." />
                  </Helmet>
                  <Home />
                </>
              }
            />
            <Route
              path="/diabetes"
              element={
                <>
                  <Helmet>
                    <title>Diabetes Dietitian | Betternow</title>
                    <meta name="description" content="Expert dietitian for diabetes management." />
                  </Helmet>
                  <DieticianHome expertise={"Diabetes"} />
                </>
              }
            />
            <Route
              path="/pcos"
              element={
                <>
                  <Helmet>
                    <title>PCOS Dietitian | Betternow</title>
                    <meta name="description" content="Professional dietitian for PCOS management." />
                  </Helmet>
                  <DieticianHome expertise={"PCOS"} />
                </>
              }
            />
            <Route
              path="/weight-management"
              element={
                <>
                  <Helmet>
                    <title>Weight Management Dietitian | Betternow</title>
                    <meta name="description" content="Specialized dietitian for weight management." />
                  </Helmet>
                  <DieticianHome expertise={"Weight Management"} />
                </>
              }
            />
            <Route
              path="/gut-health"
              element={
                <>
                  <Helmet>
                    <title>Gut Health Dietitian | Betternow</title>
                    <meta name="description" content="Expert dietitian for gut health." />
                  </Helmet>
                  <DieticianHome expertise={"Gut Health"} />
                </>
              }
            />
            <Route
              path="/cholestrol"
              element={
                <>
                  <Helmet>
                    <title>Cholesterol Management Dietitian | Betternow</title>
                    <meta name="description" content="Dietitian for cholesterol management." />
                  </Helmet>
                  <DieticianHome expertise={"Cholesterol"} />
                </>
              }
            />
            <Route
              path="/faq"
              element={
                <>
                  <Helmet>
                    <title>FAQs | Betternow</title>
                    <meta name="description" content="Frequently asked questions about our services." />
                  </Helmet>
                  <FAQ />
                </>
              }
            />
            <Route
              path="/blog"
              element={
                <>
                  <Helmet>
                    <title>Blog | Betternow</title>
                    <meta name="description" content="Blogs" />
                  </Helmet>
                  <Blogs />
                </>
              }
            />
            <Route
            path="/blog/:id" // Dynamic parameter (e.g., blog id or slug)
            element={
              <>
                <Helmet>
                  <title>Blog Post | Betternow</title>
                  <meta name="description" content="Blog post details" />
                </Helmet>
                <BlogDetail />
              </>
            }
          />
            <Route
              path="/contact"
              element={
                <>
                  <Helmet>
                    <title>Contact Us | Betternow</title>
                    <meta name="description" content="Get in touch with us." />
                  </Helmet>
                  <ContactUs />
                </>
              }
            />
            <Route
              path="/terms-of-services"
              element={
                <>
                  <Helmet>
                    <title>Terms of Service | Betternow</title>
                    <meta name="description" content="Read our terms of service." />
                  </Helmet>
                  <Terms />
                </>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <>
                  <Helmet>
                    <title>Privacy Policy | Betternow</title>
                    <meta name="description" content="Read our privacy policy." />
                  </Helmet>
                  <Privacy />
                </>
              }
            />
            <Route
              path="/refund-policy"
              element={
                <>
                  <Helmet>
                    <title>Refund Policy | Betternow</title>
                    <meta name="description" content="Our refund policy." />
                  </Helmet>
                  <Refund />
                </>
              }
            />
            <Route
              path={`/dietitian-profile/:userId`}
              element={
                <>
                  <Helmet>
                    <title>Dietitian Profile | Betternow</title>
                    <meta name="description" content="View the dietitian's profile." />
                  </Helmet>
                  <DoctorProfile />
                </>
              }
            />
            <Route
              path="/dietitian-jobs"
              element={
                <>
                  <Helmet>
                    <title>Dietitian Jobs | Betternow</title>
                    <meta name="description" content="Apply for dietitian jobs." />
                  </Helmet>
                  <JoinUs />
                </>
              }
            />
            <Route
              path="/checkout/:userId"
              element={
                <>
                  <Helmet>
                    <title>Checkout | Betternow</title>
                    <meta name="description" content="Complete your checkout." />
                  </Helmet>
                  <Checkout />
                </>
              }
            />
            <Route
              path="/congo"
              element={
                <>
                  <Helmet>
                    <title>Congratulations | Betternow</title>
                    <meta name="description" content="Congratulations on your purchase!" />
                  </Helmet>
                  <Congo />
                </>
              }
            />

            <Route element={<PatientProtectedRoute />}>
              <Route
                path="/profile"
                element={
                  <>
                    <Helmet>
                      <title>Patient Profile | Betternow</title>
                      <meta name="description" content="View your profile." />
                    </Helmet>
                    <PatientProfile />
                  </>
                }
              />
            </Route>

            <Route element={<NotFilledDietitianProtectedRoute />}>
              <Route
                path="/register"
                element={
                  <>
                    <Helmet>
                      <title>Register | Betternow</title>
                      <meta name="description" content="Complete your dietitian registration." />
                    </Helmet>
                    <Enrollment />
                  </>
                }
              />
            </Route>

            <Route element={<PendingDietitianProtectedRoute />}>
              <Route
                path="/enrollmentStatus"
                element={
                  <>
                    <Helmet>
                      <title>Enrollment Status | Betternow</title>
                      <meta name="description" content="Check your enrollment status." />
                    </Helmet>
                    <EnrollmentStatus />
                  </>
                }
              />
            </Route>

            <Route element={<DietitianProtectedRoute />}>
              <Route
                path="/dashboard"
                element={
                  <>
                    <Helmet>
                      <title>Dashboard | Betternow</title>
                      <meta name="description" content="Access your dashboard." />
                    </Helmet>
                    <Dashboard />
                  </>
                }
              />
              <Route
                path="/dashboard/edit"
                element={
                  <>
                    <Helmet>
                      <title>Edit Profile | Betternow</title>
                      <meta name="description" content="Edit your profile." />
                    </Helmet>
                    <EditProfile />
                  </>
                }
              />
              <Route
                path="/dashboard/profile"
                element={
                  <>
                    <Helmet>
                      <title>Profile | Betternow</title>
                      <meta name="description" content="View your profile." />
                    </Helmet>
                    <Profile />
                  </>
                }
              />
              <Route
                path="/dashboard/chat"
                element={
                  <>
                    <Helmet>
                      <title>Chat | Betternow</title>
                      <meta name="description" content="Chat with your patients." />
                    </Helmet>
                    <ChatApp />
                  </>
                }
              />
            <Route
              path="/dashboard/add-availability"
              element={
                <>
                  <Helmet>
                      <title>Add Your Availability | Betternow</title>
                      <meta name="description" content="Add your availability." />
                    </Helmet>
                  <Settings />
                </>
              }
            />
            <Route
              path="/dashboard/appointment"
              element={
                <>
                  <Helmet>
                      <title>Appoinments | Betternow</title>
                      <meta name="description" content="Manage your appointments." />
                    </Helmet>
                  <Appointment />
                </>
              }
            />
            <Route
              path="/dashboard/patient-medical-form"
              element={
                <>
                  <Helmet>
                      <title>Patient Medical History | Betternow</title>
                      <meta name="description" content="Add patient medical history." />
                    </Helmet>
                  <PatientMedicalHistory />
                </>
              }
            />
            </Route>

            <Route element={<AdminProtectedRoute />}>
              <Route
                path="/admin"
                element={
                  <>
                    <Helmet>
                      <title>Admin Dashboard | Betternow</title>
                      <meta name="description" content="Manage the platform as an admin." />
                    </Helmet>
                    <AdminDashboard />
                  </>
                }
              />
            </Route>
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
