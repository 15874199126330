import React, { useState } from "react";
import { LoginCredentials } from "../../model/CredentialPayloadModel";
import toast, { Toaster } from "react-hot-toast";
import {
  PASSWORD_REQUIRED,
  USERNAME_REQUIRED,
} from "../../constants/ErrorMessage";
import axios from "axios";
import LoginModal from "../Modals/LoginModal";

interface Prop {
  setIsOpen: Function;
}
const ForgotPasswordForm = ({ setIsOpen }: Prop) => {
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [loading, setLoading] = useState({
    type: "",
    value: false,
  });
  const [formData, setFormData] = useState<LoginCredentials>({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.username) newErrors.username = USERNAME_REQUIRED;
    if (!formData.password) newErrors.password = PASSWORD_REQUIRED;
    return newErrors;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const phonePattern = /^[2-9]{2}[0-9]{8}$/;
    if (name === "username") {
      if (value || value === "") {
        setFormData({ ...formData, [name]: value });
      }
    if(!value.match(phonePattern)){
      setErrors({...errors,[name]:'Enter a valid Phone number invalid'})
    } else {
      console.log("ok")
      setErrors({ ...errors, [name]: "" });
    }
    } else if (name === "password") {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newErrors = validate();
    console.log(newErrors);
    console.log(formData);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      const { ...payload } = formData;
      console.log("payload", payload);
      setLoading({ type: "login", value: true });

      const forgotPassword = () => {
        axios
          .post("https://backend.betternow.co.in/users/reset-password", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response: any) => {
            setLoading({ type: "login", value: false });
            toast.success(response.data.message);
          })
          .catch((error) => {
            setLoading({ type: "login", value: false });

            console.error(
              "Error:",
              error.response ? error.response.data : error.message,
            );
            toast.error(
              error.response ? error.response.data.message : error.message,
            );
          });
      };
      forgotPassword();
    } catch (error: any) {
      toast.error("An error occured. Please retry after sometime.");
      console.log("Error:", error);
    }
  };

  const handleSignIn =async()=>{
    setIsOpenLoginModal(true);
  }

  return (
    <>
      <section className="bg-[#EFEFEA] rounded-lg w-full py-6 justify-center flex">
        <Toaster position="top-center" reverseOrder={false} />
        <div className="px-4 lg:px-16 w-full">
            <>
              <div className="mb-8  text-[20px] lg:text-[36px] font-bold text-center text-[#315145]">
                Forgot Password
              </div>
              <form action="#" className="space-y-6" onSubmit={handleSubmit}>
                <div className="relative">
                  <input
                    type="number"
                    className=" bg-[#F4F5F4] text-[12px] lg:text-[20px] rounded-lg w-full p-2.5 px-4 placeholder-[#797979] border-[3px] border-[#315145] focus:outline-none"
                    placeholder="Phone Number"
                    onChange={handleInputChange}
                    value={formData.username}
                    name="username"
                    required
                  />
                  {errors && (
                    <span className="absolute left-0 bottom-[-18px] text-red-500 text-sm">
                      {errors.username}
                    </span>
                  )}
                </div>
                <div className="relative">
                  <input
                    type="password"
                    className=" bg-[#EFEFEA] text-[12px] lg:text-[20px] rounded-lg w-full p-2.5 px-4 placeholder-[#797979] border-[3px] border-[#315145] focus:outline-none"
                    placeholder="New Password"
                    onChange={handleInputChange}
                    id="password"
                    value={formData.password}
                    name="password"
                  />
                  {errors && (
                    <span className="absolute left-0 bottom-[-18px] text-red-500 text-sm">
                      {errors.password}
                    </span>
                  )}
                </div>

                <div className="flex gap-[8px]">
                  {/* <div className="text-[10px] absolute top-[-14px] left-[100%] translate-x-[-100%] w-max">{"New user?"}</div> */}
                  <button
                    type="submit"
                    className="bg-[#315145] text-white py-2 rounded-lg text-[12px] lg:text-[24px] font-medium w-full"
                  >
                    {loading.type === "login" && loading.value
                      ? "Wait..."
                      : "Forgot Password"}
                  </button>
                  </div> 
                  <span
                      onClick={handleSignIn}
                      className="text-center text-underline py-2 rounded-lg text-[14px] w-full xs:text-[11px]"
                      style={{color:'blue', textDecoration:'underline'}}
                    >
                      {"Sign In"}
                    </span>                 
              </form>  
            </>
            {isOpenLoginModal &&
            <LoginModal
        setIsOpenLoginModal={setIsOpenLoginModal}
        isOpenLoginModal={isOpenLoginModal}
      />}
        </div>
      </section>
    </>
  );
};

export default ForgotPasswordForm;
