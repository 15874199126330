import React from "react";

interface InputFieldProps {
  label: string;
  inputType: string;
  name: string;
  value: string | string[];
  onChange: (name: string, value: string | string[]) => void;
  options?: { label: string; value: string }[];
  required?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  inputType,
  name,
  value,
  onChange,
  options,
  required = false,
}) => {

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (inputType === "checkbox") {
      const newValue = (value as string[]).includes(e.target.value)
        ? (value as string[]).filter((v) => v !== e.target.value)
        : [...(value as string[]), e.target.value];
      onChange(name, newValue);
    } else {
      onChange(name, e.target.value);
    }
  };

  return (
    <div className="mb-4">
      <label className="block font-semibold mb-2 text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      {inputType === "number" && (
        <input
          type="number"
          name={name}
          value={value as string}
          onChange={handleChange}
          className="border border-gray-300 p-3 w-full rounded-md focus:ring-2 focus:ring-teal-500 focus:outline-none transition duration-300"
          required={required}
        />
      )}
      {inputType === "text" && (
        <input
          type="text"
          name={name}
          value={value as string}
          onChange={handleChange}
          className="border border-gray-300 p-3 w-full rounded-md focus:ring-2 focus:ring-teal-500 focus:outline-none transition duration-300"
          required={required}
        />
      )}
      {inputType === "checkbox" && (
        <div className="space-x-4">
          {options?.map((option) => (
            <label key={option.value} className="inline-flex items-center">
              <input
                type="checkbox"
                name={name}
                value={option.value}
                checked={(value as string[]).includes(option.value)}
                onChange={handleChange}
                className="form-checkbox h-4 w-4 text-teal-600 transition duration-300 focus:ring-teal-500"
              />
              <span className="ml-2 text-gray-600">{option.label}</span>
            </label>
          ))}
        </div>
      )}
      {inputType === "radio" && (
        <div className="space-x-4">
          {options?.map((option) => (
            <label key={option.value} className="inline-flex items-center">
              <input
                type="radio"
                name={name}
                value={option.value}
                checked={value === option.value}
                onChange={handleChange}
                className="form-radio h-4 w-4 text-teal-600 transition duration-300 focus:ring-teal-500"
              />
              <span className="ml-2 text-gray-600">{option.label}</span>
            </label>
          ))}
        </div>
      )}
      {inputType === "searchable-dropdown" && (
       <div className="mb-4">
       <select
         className="border rounded-lg p-2 w-full"
         value={value as string}
         onChange={handleChange}
         required={required}
       >
         <option value="" disabled>
           Select an option
         </option>
         {options?.map((option) => (
           <option key={option.value} value={option.value}>
             {option.label}
           </option>
         ))}
       </select>
     </div>
      )}
    </div>
  );
};

export default InputField;
