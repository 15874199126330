import { PatientMedicalInputFieldProps } from "../model/PatientMedicalFormModel";

export const formFields: PatientMedicalInputFieldProps[] = [
  {
    label: "Weight",
    inputType: "text",
    name: "weight",
    required: true,
  },
  {
    label: "Height",
    inputType: "text",
    name: "height",
    required: true,
  },
  {
    label: "What is your Occupation?",
    inputType: "text",
    name: "occupation",
    required: true,
  },
  {
    label: "Conditions",
    inputType: "checkbox",
    name: "disease",
    options: [
      { label: "Diabetes", value: "diabetes" },
      { label: "Pre-Diabetes", value: "pre-diabetes" },
      { label: "Obesity", value: "obesity" },
      { label: "Thyroid", value: "thyroid" },
      { label: "Gut Health", value: "gut-health" },
      { label: "PCOS/PCOD", value: "pcos-pcod" },
      { label: "Hypertension", value: "hypertension" },
      { label: "Other", value: "other" },
    ],
  },
  {
    label: "Do you have any family history of any medical condition?",
    inputType: "text",
    name: "medicalCondition",
  },
  {
    label: "When did you get your last blood test done?",
    inputType: "radio",
    name: "lastBloodTest",
    options: [
      { label: "In last 3 months", value: "3-months" },
      { label: "> 3 months", value: "more-than-3-months" },
      { label: ">6 months", value: "more-than-6-months" },
    ],
    required: true,
  },
  {
    label: "Do you take any supplements or medication?",
    inputType: "radio",
    name: "supplements",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
  },
  {
    label: "Do you have GI disorders?",
    inputType: "checkbox",
    name: "giDisorders",
    options: [
      { label: "Acidity", value: "acidity" },
      { label: "Constipation", value: "constipation" },
      { label: "Bloating", value: "bloating" },
      { label: "Other", value: "other" },
    ],
  },
  {
    label: "What is your goal?",
    inputType: "radio",
    name: "goal",
    options: [
      { label: "Weight Loss", value: "weight-loss" },
      { label: "Weight Gain", value: "weight-gain" },
      { label: "Diabetes Management", value: "diabetes-management" },
      {
        label: "Fitness & Healthy Lifestyle",
        value: "fitness-healthy-lifestyle",
      },
      { label: "Thyroid Management", value: "thyroid-management" },
      { label: "Other", value: "other" },
    ],
  },
  {
    label: "Have you followed any diet plan earlier?",
    inputType: "radio",
    name: "previousDietPlan",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
  },
  {
    label: "Are you involved in any of the following physical activity?",
    inputType: "checkbox",
    name: "physicalActivity",
    options: [
      { label: "Running", value: "running" },
      { label: "Walking", value: "walking" },
      { label: "Yoga", value: "yoga" },
      { label: "Strength Training", value: "strength-training" },
      { label: "None", value: "none" },
    ],
  },
  {
    label: "How often do you do workout?",
    inputType: "radio",
    name: "workout",
    options: [
      { label: "Everyday", value: "everyday" },
      { label: "5 Days a week", value: "5-days-week" },
      { label: "3 or more than 3 days a week", value: "3-days-week" },
      { label: "Less then 3 days a week", value: "less-than-3-days-week" },
    ],
  },
  {
    label: "What type of food do you take?",
    inputType: "radio",
    name: "foodType",
    options: [
      { label: "Pure veg", value: "pure-veg" },
      { label: "Non-veg", value: "non-veg" },
      { label: "Veg but only prefer egg", value: "veg-egg" },
      { label: "Vegan", value: "vegan" },
    ],
  },
  {
    label: "Do you have any food allergies?",
    inputType: "text",
    name: "foodAllergies",
  },
  {
    label: "How frequent do you eat outside?",
    inputType: "radio",
    name: "eatOutside",
    options: [
      { label: "Everyday", value: "everyday" },
      { label: "2-3 times in a week", value: "3-times" },
      { label: "Once in 10-15 days", value: "once-10-days" },
      { label: "Once in a month", value: "once-month" },
      { label: "Never", value: "never" },
    ],
  },
  {
    label:
      "Do you want me to avoid any specific fruits/vegetables or any other food item while meal preparing for you?",
    inputType: "text",
    name: "avoidFruits",
  },
  {
    label: "How many meals do you take in a day?",
    inputType: "radio",
    name: "mealsPerDay",
    options: [
      { label: "4-5", value: "4-times" },
      { label: "3", value: "3-times" },
      { label: "2", value: "2-times" },
      { label: "1", value: "1-time" },
    ],
  },
  {
    label: "Please specify your meal timings?",
    inputType: "text",
    name: "mealTimings",
  },
  {
    label: "Do you stay with your family or in a PG or Hostel?",
    inputType: "text",
    name: "pgHostel",
  },
  {
    label: "How much is your daily water intake?",
    inputType: "radio",
    name: "dailyWaterIntake",
    options: [
      { label: "10-12 Glasses", value: "10-12" },
      { label: "8-10 Glasses", value: "8-10" },
      { label: "5-7 Glasses", value: "5-7" },
      { label: "<5 Glasses", value: "less-than-5" },
    ],
  },
  {
    label: "For how many hours do you sleep at night?",
    inputType: "radio",
    name: "sleepHour",
    options: [
      { label: ">8 Hours", value: "greater-than-8" },
      { label: "7-8 Hours", value: "7-8" },
      { label: "5-7 Hours", value: "5-7" },
      { label: "Less than 5 hours", value: "less-than-5" },
    ],
  },
  {
    label: "Where do your rate your stress level?",
    inputType: "radio",
    name: "stressLevel",
    options: [
      { label: "1 (Ignore)", value: "ignore" },
      { label: "2 (Very Low)", value: "very-low" },
      { label: "3 (Mild)", value: "mild" },
      { label: "4 (Moderate)", value: "moderate" },
      { label: "5 (Severe)", value: "severe" },
    ],
  },
];
