import { RootState } from "../store";

export const loggedUserDetailSelector = (state: RootState) =>
  state.user.user ?? null;

export const isDieticianVerifiedSelector = (state: RootState) =>
  state.user.dietitian?.isDieticianVerified;

export const dieticianEnrollmentDataSelector = (state: RootState) =>
  state.user.dietitian ?? null;

export const userRoleSelector = (state: RootState) => state.user.user?.role;
export const userNameSelector = (state: RootState) => state.user.user?.name;
//Change role Enum & move to dietician selector

export const loginStatusSelector = (state: RootState) => state.user.loading;

export const loggedInUserSelector = (state: RootState) =>
  state.user.user ?? null;
