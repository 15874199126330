import DefaultLayout from "../Layout/DefaultLayout";
import { getDietitian } from "../../utils/DietitianApis";
import { useState, useEffect } from "react";
import EnrollmentForm from "./PopUp";

const Profile = () => {
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [dietitianDetails, setDietitianDetails] = useState<any>()
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dietitianData = await getDietitian(); // Assuming getDietitian returns a promise
        console.log("d", dietitianData);

        if (dietitianData) {
          setDietitianDetails(dietitianData);
        }
      } catch (error) {
        console.error("Error fetching dietitian data:", error);
      } finally {
        setLoading(false); // Stop loading once the data is fetched
      }
    };
    fetchData();
  }, []); // Depend on token instead of userId
  if (loading) {
    // Optionally, you can return a loading spinner or placeholder here
    return <div>Loading...</div>;
  }
  const handlePopup = () => {
    setShowPopup(!showPopup); // Toggle the popup visibility
  };
  const handleUpdateUserData = (updatedUserData: any) => {
    setDietitianDetails(updatedUserData.data);
    console.log("upd", updatedUserData)
  };

  return (

    <DefaultLayout>
      <div className="w-full p-8 bg-[#FFFFF5]">

        <div className="gap-[16px] sm:gap-[4px] flex-col justify-between rounded-lg w-full bg-[#EFEFEA] flex sm:flex-row  lg:flex-row p-8">
          <div className="w-[132px] h-[132px] mx-auto sm:w-[10vw] sm:h-[10vw] flex justify-center my-auto">
            <img className="rounded-[100%] w-[100%] h-[100%]" src={dietitianDetails?.professionalImage} alt="" />
          </div>

          <div className="flex flex-col sm:px-4 lg:w-3/4 justify-center md:px-8 text-[#315145] lg:p-0">
            <div className="flex flex-row justify-between items-center border-b-[0.5px] border-[#A0A0A0] pt-2 pb-4">
              <div className="flex flex-col">
                <div className="flex flex-row justify-between xs:text-[25px] sm:text-[28px] lg:text-[34px] font-medium">
                  <div>
                    {dietitianDetails?.user?.gender === "Male" ? "Mr" : "Ms"} {dietitianDetails?.user?.name}
                  </div>
                  <button onClick={handlePopup}>
                    <div className="pl-5 pb-1">
                      <img src="../compose1.png" alt="" />
                    </div>
                  </button>
                </div>
                <div className="text-[16px] text-[#000000] lg:text-[18px]">
                  {dietitianDetails?.areaOfExpertise &&
                    dietitianDetails.areaOfExpertise.length > 0 && (
                      <>
                        {dietitianDetails?.areaOfExpertise?.map((area: any, index: any) => {
                          const isLast = index === dietitianDetails.areaOfExpertise.length - 1;
                          const isSecondLast = index === dietitianDetails.areaOfExpertise.length - 2;
                          return (
                            <span key={index}>
                              {area}
                              {isLast ? "" : isSecondLast ? " and " : ", "}
                            </span>
                          );
                        })}
                        <span> Expert</span>
                      </>
                    )}
                </div>
              </div>
              <div className="hidden sm:flex flex flex-row flex-wrap w-2/4 justify-end">
                <div className="rounded-[50px] bg-[#D9D9D9] py-1.5 px-[2px] pe-[10px] font-medium text-[8px] mr-2 mb-2 text-center w-[85px] h-[28px] flex justify-around items-center">
                  <span className="mr-1 text-[12px] rounded-[100%] h-[20px] w-[20px] bg-white p-1 flex justify-center items-center">
                    <img className="h-[10px]" src="../star1.png" alt="" />
                  </span>
                  Top Rated
                </div>
                <div className="rounded-[50px] bg-[#D9D9D9] py-1.5 pe-[10px] font-medium text-[8px] mr-2 mb-2 text-center w-[124px] h-[28px] flex justify-around items-center">
                  <span className="mr-1 text-[16px] rounded-full bg-white p-1 h-[20px] w-[20px] flex justify-center items-center">
                    <img className="h-[10px]" src="/Logo_Real.png" alt="" />
                  </span>
                  BetterNow Certified
                </div>
                <div className="rounded-[50px] bg-[#D9D9D9] py-1.5 px-2 pe-[15px] font-medium text-[8px] mr-2 mb-2 text-center w-[144px] h-[28px] flex justify-between items-center">
                  <span className="mr-1 text-[10px] rounded-full bg-white p-1 h-[20px] w-[20px] flex justify-center items-center">
                    <img className="h-[10px]" src="../HeartVector.png" alt="" />
                  </span>
                  {dietitianDetails?.noOfConsultations} happy client sessions
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row md:text-[14px]  text-[#000000] py-4 sm:text-[12px] lg:text-[15px]">
              <div className="sm:w-1/2 space-y-2 sm:border-r-[0.5px] sm:border-[#A0A0A0]">
                <div>Delhi NCR, INDIA</div>
                <div>
                  {dietitianDetails?.yearOfExperience ? dietitianDetails?.yearOfExperience + "" : "0"} Years Experience
                </div>
                <div>
                  Fluent in{" "}
                  {dietitianDetails?.spokenLanguages.map((l: any, ind: any) => {
                    let str = l;
                    if (ind < dietitianDetails?.spokenLanguages.length - 1) str += ", ";
                    return str;
                  })}
                </div>
              </div>
              <div className="mt-[8px] sm:mt-[0] sm:w-1/2  flex flex-col justify-center sm:items-center ">
                <div className="space-y-2">
                  <div className="text-left">{dietitianDetails?.user?.phone}</div>
                  <div className="text-left">{dietitianDetails?.user?.email}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row my-4 gap-2">
          <div className="w-full flex flex-col gap-2">
            <div className="bg-[#EFEFEA] p-8 rounded-lg xs:pr-[16px]">
              <div className="mb-6">
                <div className="text-[18px] mb-1 text-[#315145]">About</div>
                <div className="text-[14px] w-4/5 text-[#797979]">
                  {dietitianDetails?.intro}
                </div>
              </div>
              <div className="mb-6">
                <div className="text-[18px] mb-1 text-[#315145]">Expertise</div>
                <div className="text-[14px] w-4/5 text-[#797979]">
                  {dietitianDetails?.areaOfExpertise.join(", ")}
                </div>
              </div>
              <div className="mb-6">
                <div className="text-[18px] mb-1 text-[#315145]">Consultation fee</div>
                <div className="text-[14px] w-4/5 text-[#797979]">
                  One time consultation Fee: {dietitianDetails?.oneTimeConsultationFee} <br />
                  Monthly Consultation fee: {dietitianDetails?.monthlyConsultationFee}
                </div>
              </div>
              <div className="mb-6">
                <div className="text-[18px] mb-1 text-[#315145]">PAN Number</div>
                {dietitianDetails?.pan ? (
                  <div className="text-[14px] w-4/5 text-[#797979]">
                    {dietitianDetails.pan}
                  </div>
                ) : (
                  <div onClick={()=>setShowPopup(true)} className="text-[14px] w-4/5 text-red-600" style={{textDecoration:'underline'}}>
                    Please update your PAN Number
                  </div>
                )}
              </div>

              <div className="mb-6">
                <div className="text-[18px] mb-1 text-[#315145]">UPI Id</div>
                {dietitianDetails?.upiId ? (
                  <div className="text-[14px] w-4/5 text-[#797979]">
                    {dietitianDetails.upiId}
                  </div>
                ) : (
                  <div onClick={()=>setShowPopup(true)} className="text-[14px] w-4/5 text-red-600" style={{textDecoration:'underline'}}>
                    Please update your UPI Id
                  </div>
                )}
              </div>

            </div>



          </div>

        </div>
      </div>
      {/* </div> */}

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 pt-0 rounded-lg w-full max-w-lg relative h-[86vh] overflow-y-scroll">
            {/* Close Button */}
            <button
              className="sticky top-[10px] left-[100%] right-4 text-black text-lg z-[100]"
              onClick={handlePopup}
            >
              &times;
            </button>

            {/* Logo */}
            {/* <div className="flex items-center  mb-2 sticky top-0 bg-white z-[10] pt-[1.5em]">
              <img src="/betterlogogreen.png" alt="Logo" className=" h-10" />
            </div> */}

            {/* Form Title */}
            <h2 className="text-xl font-bold">Edit your details:</h2>
            <EnrollmentForm
              data={dietitianDetails} // Pass current userData
              onClose={handlePopup} // Close modal on success or cancel
              onUpdate={handleUpdateUserData}
            />
          </div>
        </div>
      )}
    </DefaultLayout>
  );
};

export default Profile;
