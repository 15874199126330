// import React from "react";

// interface BlogCardProps {
//   imageSrc: string;
//   title: string;
//   description: string;
//   vertical: string;
//   id: number;
//   onClick: (id: number) => void;
// }

// const BlogCard: React.FC<BlogCardProps> = ({
//   imageSrc,
//   title,
//   description,
//   vertical,
//   id,
//   onClick,
// }) => {
//   return (
//     <div className="flex flex-col lg:flex-row justify-between cursor-pointer my-4 sm:my-24" 
//     onClick={() => onClick(id)}>
//       <img
//         className="object-cover h-[250px] w-full lg:w-3/5 lg:h-[500px] rounded-lg"
//         src={imageSrc}
//         alt={title}
//       />
//       <div className="flex flex-col xs:py-4 lg:w-2/5 text-[#325243] lg:px-8 relative">
//         <div className="text-[20px] lg:text-[40px] font-semibold mb-2 lg:mb-4">
//           {title}
//         </div>
//         <div className="text-[12px] lg:text-[20px] mt-2 lg:mt-8">
//           {description.split(' ').slice(0, 40).join(' ')}...
//         </div>
        
//       </div>
//     </div>
//   );
// };

// export default BlogCard;
import React from "react";

interface BlogCardProps {
  imageSrc: string;
  title: string;
  description: string;
  vertical: string;
  id: string;
  onClick: (id: string) => void;
}

const BlogCard: React.FC<BlogCardProps> = ({
  imageSrc,
  title,
  description,
  vertical,
  id,
  onClick,
}) => {
  return (
    <div className="lg:grid lg:grid-cols-[1.4fr_1fr] flex flex-col justify-between cursor-pointer my-4 sm:my-24" 
    onClick={() => onClick(id)}>
      <img
        className="object-cover h-[250px] w-full  lg:h-[500px] rounded-lg"
        src={imageSrc}
        alt={title}
      />
      <div className="flex flex-col xs:py-4  text-[#325243] lg:px-8 relative">
        <div className="text-[20px] lg:text-[33px] font-semibold mb-2 lg:mb-4">
          {title}
        </div>
        <div className="text-[12px] lg:text-[20px] mt-2 lg:mt-8">
          {description.split(' ').slice(0, 40).join(' ')}...
        </div>
        
      </div>
    </div>
  );
};

export default BlogCard;
