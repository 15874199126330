import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import blogs from "../../constants/DummyBlogData";
import Footer from "../Footer";
import Navbar from "../Navbar/Navbar";

interface BlogDetailProps {
  imageSrc: string;
  title: string;
  description: string[];
  vertical: string;
  content: {
    subTitle: string;
    subDescription: {
      type: "para" | "bullet";
      subDescriptionTitle: string;
      subDescriptionContent: string[];
    }[];
  }[];
}

const BlogDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Get the blog id from the route parameters
  const [blogData, setBlogData] = useState<BlogDetailProps | null>(null); // State to store the current blog
  const [selectedTab, setSelectedTab] = useState("All"); // Add state for the selected tab
  const navigate = useNavigate();
  // Fetch the blog data when the component mounts
  useEffect(() => {
    const blog = blogs.find((data) => (data.id) === (id));
    if (blog) {
      const castedBlog = {
        ...blog,
        content: blog.content?.map((section) => ({
          ...section,
          subDescription: section.subDescription?.map((subItem) => ({
            ...subItem,
            type: subItem.type as "para" | "bullet", // Cast the type here
          })),
        })),
      };
      setBlogData(castedBlog);
    }
  }, [id]);


  if (!blogData) {
    return <div>Blog not found</div>; // Show this message if the blog is not found
  }

  const { imageSrc, title, description, content } = blogData;

const blogType = [
  { tabName: "All" },
  { tabName: "Diabetes" },
  { tabName: "PCOD/PCOS" },
  { tabName: "Gut Health" },
  { tabName: "Mindful Eating" },
  { tabName: "Lifestyle Management" },
];
const handleTabClick = (tabName: string) => {
  setSelectedTab(tabName);
  navigate('/blog', { state: { selectedTab: tabName } });
};
  return (
    <div className="font-poppins bg-[#FFFFF5]">
      <Navbar
        backgroundColor="#FFFFF5"
        textColor="#325243"
        logo="/betterNowLogodark.png"
      />
      <div className="px-8 lg:px-16">
      <div className="text-[30px] lg:text-[60px] font-semibold text-[#325243] mt-4 lg:mt-8">
        Blog
      </div>
      <div className="text-[12px] lg:text-[20px] text-[#325243]">
        Healthcare Tips
      </div>

      {/* Blog Category Tabs */}
      <div className="flex flex-row mt-10 flex-wrap">
        {blogType.map((item, index) => (
          <button
            key={index}
            type="button"

            className={`rounded-[30px] lg:min-h-[54px] bg-[#325243] text-[12px] lg:text-[18px] text-[#FFFFF5] font-medium sm:flex-1 px-4 py-2 lg:py-[2px] m-1 ${
              selectedTab === item.tabName ? "bg-[#4B794A]" : "bg-[#325243]"
            }`} // Change the background color when selected
            onClick={() => handleTabClick(item.tabName)} // Handle tab click
          >
            {item.tabName}
          </button>
        ))}
      </div>
      </div>
    <div className="flex flex-col justify-between my-24">
      {/* Blog Image */}
      <div className="px-8 lg:px-16">
      <img
        className="object-cover  max-h-[600px] w-full rounded-[20px]"
        src={imageSrc}
        alt={title}
      />
      </div>
      {/* Blog Content */}
      <div className="flex flex-col px-8  text-[#325243] lg:px-16 relative">
        {/* Blog Title */}
        <h1 className="text-[20px] lg:text-[40px] font-semibold my-2 lg:my-4">
          {title}
        </h1>
        {/* Blog Description */}
        {description?.map((desc, descIndex) => (
          <p
            key={descIndex}
            // className="text-[12px] lg:text-[20px] my-2 lg:my-8"
          >
            {desc}
          </p>
        ))}
        {/* Render Blog Content */}
        <div>
          {content?.map((section, index) => (
            <div key={index} className="my-4">
              {/* Subtitle (H2) */}
              <h2 className="text-[18px] lg:text-[28px] font-bold mb-2">
                {section.subTitle}
              </h2>
              {/* Render Sub Descriptions */}
              {section.subDescription?.map((subItem, subIndex) => (
                <div key={subIndex} className="mb-4">
                  {/* Sub Description Title (H3) */}
                  <h3 className="text-[16px] lg:text-[24px] font-semibold mb-2">
                    {subItem.subDescriptionTitle}
                  </h3>
                  {/* Paragraph or Bullet Points */}
                  {subItem.type === "para" ? (
                    subItem.subDescriptionContent?.map(
                      (paragraph, paraIndex) => (
                        <p
                          key={paraIndex}
                          className="text-[14px] lg:text-[18px] mb-2"
                        >
                          {paragraph}
                        </p>
                      )
                    )
                  ) : (
                    <ul className="list-disc list-inside ml-4">
                      {subItem.subDescriptionContent?.map(
                        (bullet, bulletIndex) => (
                          <li
                            key={bulletIndex}
                            className="text-[14px] lg:text-[18px] mb-2"
                          >
                            {bullet}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default BlogDetail;
