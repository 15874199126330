// import React, { useState, useRef } from "react";
// import BlogCard from "./BlogCard";
// import Pagination from "./Pagination";
// import { blogs } from "../../constants/DummyBlogData";
// import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

// const blogType = [
//   { tabName: "Diabetes" },
//   { tabName: "PCOD/PCOS" },
//   { tabName: "Gut Health" },
//   { tabName: "Mindful Eating" },
//   { tabName: "Lifestyle Management" },
// ];

// const SectionOne: React.FC = () => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 5;
//   const blogListRef = useRef<HTMLDivElement>(null);
//   const navigate = useNavigate(); // Initialize navigate

//   const totalBlogs = blogs.length;
//   const indexOfLastBlog = currentPage * itemsPerPage;
//   const indexOfFirstBlog = indexOfLastBlog - itemsPerPage;
//   const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

//   const handlePageChange = (page: number) => {
//     setCurrentPage(page);
//     if (blogListRef.current) {
//       blogListRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   };

//   const handleBlogClick = (id: number) => {
//     // Navigate to the blog detail page using the blog id
//     navigate(`/blog/${id}`);
//   };

//   return (
//     <div className="bg-[#FFFFF5] flex flex-col px-8 lg:px-16 pb-8">
//       <div className="text-[30px] lg:text-[60px] font-semibold text-[#325243] mt-4 lg:mt-8">
//         Blog
//       </div>
//       <div className="text-[12px] lg:text-[20px] text-[#325243]">
//         Healthcare Tips
//       </div>

//       {/* Blog Category Tabs */}
//       <div className="flex flex-row mt-10 flex-wrap">
//         {blogType.map((item, index) => (
//           <button
//             key={index}
//             type="button"
//             className="rounded-[30px] lg:min-h-[54px] bg-[#325243] text-[12px] lg:text-[20px] text-[#FFFFF5] font-medium sm:flex-1 px-4  py-2 lg:py-0 m-1 "
//           >
//             {item.tabName}
//           </button>
//         ))}
//       </div>

//       <div className="flex flex-row justify-between my-4 lg:mt-16 items-center">
//         <div className="text-[#325243] text-[18px] lg:text-[36px] font-semibold">
//           Most Recent Posts
//         </div>
//       </div>
//       <div className="w-full h-[1.5px] bg-[#325243]" />

//       {/* Blog List */}
//       <div ref={blogListRef} className="py-12">
//         {currentBlogs.map((item, index) => (
//           <BlogCard
//             key={index}
//             id={item.id}
//             imageSrc={item.imageSrc}
//             vertical={item.vertical}
//             title={item.title}
//             description={item.description[0]}
//             onClick={handleBlogClick} // Pass the handleBlogClick function
//           />
//         ))}
//       </div>

//       <Pagination
//         totalItems={totalBlogs}
//         itemsPerPage={itemsPerPage}
//         currentPage={currentPage}
//         onPageChange={handlePageChange}
//       />
//     </div>
//   );
// };

// export default SectionOne;
import React, { useState, useRef } from "react";
import BlogCard from "./BlogCard";
import Pagination from "./Pagination";
import { blogs } from "../../constants/DummyBlogData";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

const blogType = [
  { tabName: "All" }, // Add "All" to show all blogs initially
  { tabName: "Diabetes" },
  { tabName: "PCOD/PCOS" },
  { tabName: "Gut Health" },
  { tabName: "Mindful Eating" },
  { tabName: "Lifestyle Management" },
];
interface SectionOneProps {
  tab: any;
}

const SectionOne: React.FC<SectionOneProps> = ({tab}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState(tab || "All"); // Add state for the selected tab
  const itemsPerPage = 5;
  const blogListRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate(); // Initialize navigate

  const filteredBlogs = selectedTab === "All" ? blogs : blogs.filter(blog => blog.vertical === selectedTab); // Filter blogs based on selected tab

  const totalBlogs = filteredBlogs.length;
  const indexOfLastBlog = currentPage * itemsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - itemsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (blogListRef.current) {
      blogListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleBlogClick = (id: string) => {
    // Navigate to the blog detail page using the blog id
    navigate(`/blog/${id}`);
  };

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
    setCurrentPage(1); // Reset to the first page when the tab changes
  };

  return (
    <div className="bg-[#FFFFF5] flex flex-col px-8 lg:px-16 pb-8">
      <div className="text-[30px] lg:text-[60px] font-semibold text-[#325243] mt-4 lg:mt-8">
        Blog
      </div>
      <div className="text-[12px] lg:text-[20px] text-[#325243]">
        Healthcare Tips
      </div>

      {/* Blog Category Tabs */}
      <div className="flex flex-row mt-10 flex-wrap">
        {blogType.map((item, index) => (
          <button
            key={index}
            type="button"

            className={`rounded-[30px] lg:min-h-[54px] bg-[#325243] text-[12px] lg:text-[18px] text-[#FFFFF5] font-medium sm:flex-1 px-4 py-2 lg:py-[2px] m-1 ${
              selectedTab === item.tabName ? "bg-[#4B794A]" : "bg-[#325243]"
            }`} // Change the background color when selected
            onClick={() => handleTabClick(item.tabName)} // Handle tab click
          >
            {item.tabName}
          </button>
        ))}
      </div>

      <div className="flex flex-row justify-between my-4 lg:mt-16 items-center">
        <div className="text-[#325243] text-[18px] lg:text-[36px] font-semibold">
          Most Recent Posts
        </div>
      </div>
      <div className="w-full h-[1.5px] bg-[#325243]" />

      {/* Blog List */}
      <div ref={blogListRef} className="py-12">
        {currentBlogs.map((item, index) => (
          <BlogCard
            key={index}
            id={item.id}
            imageSrc={item.imageSrc}
            vertical={item.vertical}
            title={item.title}
            description={item.description[0]}
            onClick={handleBlogClick} // Pass the handleBlogClick function
          />
        ))}
      </div>

      <Pagination
        totalItems={totalBlogs}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default SectionOne;
