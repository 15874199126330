import React, { useState } from "react";

interface ImageUploadProps {
  onImageUpload: (imageFile: File) => void;
  allowedTypes?: string[];
  maxSizeInMB?: number;
  error?: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  onImageUpload,
  allowedTypes = ["image/jpeg", "image/png"],
  maxSizeInMB = 3,
  error,
}) => {
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        setErrorMessage(
          "Invalid file type. Please select a JPEG or PNG image.",
        );
        return;
      }

      // Validate file size
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        setErrorMessage(
          `File size exceeds ${maxSizeInMB} MB. Please select a smaller file.`,
        );
        return;
      }

      // Clear previous error message
      setErrorMessage(null);
      setPreviewURL(URL.createObjectURL(file));
      onImageUpload(file);
    }
  };

  const handleRemoveImage = () => {
    setPreviewURL(null);
    setErrorMessage(null);
  };

  return (
    <div className="relative">
      <input type="file" accept="image/*" onChange={handleImageChange} />
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      {previewURL && (
        <div>
          <img
            src={previewURL}
            alt="Preview"
            style={{ width: "200px", height: "auto" }}
          />
          <button onClick={handleRemoveImage}>Remove</button>
        </div>
      )}
      {error && (
        <span className="absolute left-0 bottom-[-18px] text-red-500 text-sm">
          {error}
        </span>
      )}
    </div>
  );
};

export default ImageUpload;
