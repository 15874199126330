import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import DefaultLayout from '../Layout/DefaultLayout';
import { io } from "socket.io-client";
import { jwtDecode, JwtPayload } from 'jwt-decode';

const Chat = () => {
  const [conversations, setConversations] = useState<any[]>([]);
  const [selectedConversationId, setSelectedConversationId] = useState<string | null>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const socketRef = useRef<any>(null);
  const sortedMessages = [...messages].sort((a, b) => a.sent_at - b.sent_at);
  const token = localStorage.getItem('token');
  const decoded: any = token? jwtDecode<JwtPayload>(token): null;
  const dietiticianId = decoded?.id;
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [file, setFile] = useState<File | null>(null); // Track selected file
  const [filePreview, setFilePreview] = useState<string | null>(null); // For file preview
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    // Initialize socket.io connection
    socketRef.current = io("ws://backend.betternow.co.in");

    socketRef.current.on('connect', () => {
      console.log('WebSocket connected:', socketRef.current.id);
    });

    socketRef.current.on('newMessage', (newMessage: any) => {
      // Use functional update to ensure latest state is used
      console.log("newwww",newMessage)
      setScroll(true);
      if(newMessage.sender === 'USER') {
        setMessages((prevMessages) => [...prevMessages, {...newMessage, messageContent: JSON.parse(newMessage.messageContent)}]);

      }
    });

    return () => {
      // Clean up WebSocket connection
      socketRef.current.disconnect();
      console.log('WebSocket disconnected');
    };
  }, []); 

  useEffect(() => {
    if (selectedConversationId && socketRef.current) {
      socketRef.current.emit('joinConversation', selectedConversationId);
    }
  }, [selectedConversationId]);

  useEffect(() => {
    const fetchConversations = async () => {
      console.log(messages.length, "in fetch convo")
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(
         `https://backend.betternow.co.in/webhook/getConversations/${dietiticianId}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setConversations(response.data);
      } catch (err) {
        console.error('Failed to fetch conversations:', err);
      }
    };
    fetchConversations();
  }, []);

  useEffect(() => {
    if (selectedConversationId) {
      const fetchMessages = async () => {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(
            `https://backend.betternow.co.in/webhook/getMessagesByConversation/${selectedConversationId}`,
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          );
          setMessages(response.data);
          console.log("dat,",response.data)
        } catch (err) {
          console.error('Failed to fetch messages:', err);

        }
      };

      fetchMessages();
    }
  }, [selectedConversationId]);
  
  const isMessageInLast24Hours = () => {
    if (sortedMessages && sortedMessages.length > 0) {
      // Get current time
      const currentTime = new Date();
      
      // Loop through the sorted messages in reverse
      for (let i = sortedMessages.length - 1; i >= 0; i--) {
        const message = sortedMessages[i];
        const messageTime = new Date(message.sent_at);
        
        // Check if message was sent by user or has campaign
        if (message.sender === "USER" || message.campaign !== null) {
          console.log("found message in last 24 hours")
          // Calculate the time difference
          const timeDifference = currentTime.getTime() - messageTime.getTime();
          // Check if it's within the last 24 hours (24 hours = 86400000 ms)
          if (timeDifference <= 86400000) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    return false; // Return false if no message meets the condition
  };
  
 
  const handleSendMessage = async () => {
    if (newMessage.trim() || file) {
      const conversation = conversations.find((conv) => conv._id === selectedConversationId);
      if(!isMessageInLast24Hours()){
        console.log("message not in last 24 hours")
        const url = "https://backend.aisensy.com/campaign/t1/api/v2";
        const data = {
          apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2YzMxNGRjNmYyNmFiMGI3NjJhMDQ5MSIsIm5hbWUiOiJCZXR0ZXJub3ciLCJhcHBOYW1lIjoiQWlTZW5zeSIsImNsaWVudElkIjoiNjZjMzE0ZGI2ZjI2YWIwYjc2MmEwNDdjIiwiYWN0aXZlUGxhbiI6IkJBU0lDX01PTlRITFkiLCJpYXQiOjE3MjQwNjA4OTJ9.r7IMtMqKmxLgDycaHjNadak_F_3-S-9Vq99vtNwQo98",
          campaignName: "Dietitian Joined Chat",
          destination: (conversation?.user.phone).toString(),
          userName: "Betternow",
          templateParams: [],
          source: "new-landing-page form",
          media: {},
          buttons: [],
          carouselCards: [],
          location: {},
          paramsFallbackValue: {}
        };
  
        axios.post(url, data, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          console.log("Success:", response.data);
        })
        .catch(error => {
          console.error("Error:", error);
        });
      }
      setLoading(true)
      let res
      try {
        if (file) {
          // Upload the document
          const formData = new FormData();
          formData.append("file", file);
          const uploadResponse = await axios.post(
            "https://backend.betternow.co.in/upload",
            formData,
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          );
          const documentUrl = uploadResponse.data.media.mediaUrl; // Assuming the URL is returned in this format

          let sendHalfPayload;
          if (file.type.startsWith("image/")) {
            sendHalfPayload = {
              type: "image",
              image: {
                caption: newMessage.toString(),
                link: documentUrl,
              },
            };
          } else if (file.type.startsWith("application/")) {
            sendHalfPayload = {
              type: "document",
              document: {
                caption: newMessage.toString(),
                link: documentUrl,
                filename: file.name,
              },
            };
          }
          res = await axios.post(
            "https://apis.aisensy.com/project-apis/v1/project/66c314dc6f26ab0b762a0491/messages",
            {
              to: (conversation?.user.phone).toString(),
              recipient_type: "individual",
              ...sendHalfPayload,
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-AiSensy-Project-API-Pwd": "1a8643f4c89d945c98160",
              },
            }
          );
        } else {
          res = await axios.post(
            "https://apis.aisensy.com/project-apis/v1/project/66c314dc6f26ab0b762a0491/messages",
            {
              to: (conversation?.user.phone).toString(),
              type: "text",
              recipient_type: "individual",
              text: {
                body: newMessage.toString(),
              },
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-AiSensy-Project-API-Pwd": "1a8643f4c89d945c98160",
              },
            }
          );
        }

        console.log("res", res);
        const msgData = await axios.get(
          `https://apis.aisensy.com/project-apis/v1/project/66c314dc6f26ab0b762a0491/messages/${res.data.messages[0].id}`,
          {
            headers: {
              Accept: "application/json",
              "X-AiSensy-Project-API-Pwd": "1a8643f4c89d945c98160",
            },
          }
        );

        // Optimistically update UI with new message
          console.log("msgdata",msgData)
        setMessages((prevMessages) => [...prevMessages, {messageContent: msgData.data.message_content, conversation: selectedConversationId, messageType:msgData.data.message_type}]);
        // setMessages((prevMessages) => [...prevMessages, newMsg]);
        setNewMessage("");
        setFile(null); // Reset file after sending
        setFilePreview(null); // Reset file preview after sending
      } catch (err) {
        console.error("Failed to send message:", err);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [selectedConversationId, sortedMessages,scroll,setScroll]);

 
  const handleSelectConversation = (conversationId: string) => {
    setSelectedConversationId(conversationId);
  };

  const handleBackToConversations = () => {
    setSelectedConversationId(null);
  };
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    console.log(selectedFile);
    if (selectedFile) {
      setFile(selectedFile); // Save the selected file
      setFilePreview(URL.createObjectURL(selectedFile)); // Set file preview
    }
  };
  return (
    <DefaultLayout>
  <div className='flex h-[95vh] md:h-[100vh]'>
      <div className={`w-[100%] overflow-y-scroll ${selectedConversationId ? 'hidden' : ''} md:block md:w-[30%] h-full bg-[#fff]`}>
        <div className="flex p-4 bg-[#F0F2F5]">
          <input
            type="search"
            className="w-full p-2 h-[40px] text-[12px] lg:text-[20px] text-[#315145] rounded-lg focus:outline-none"
            placeholder="Search"
          />
        </div>
        {conversations.length>0 ?
        <div>
          {conversations.map((conv) => (
            <div
              key={conv._id}
              onClick={() => handleSelectConversation(conv._id)}
              className={`p-4 border-b border-gray-200 cursor-pointer ${selectedConversationId === conv._id ? 'bg-gray-100' : ''}`}
            >
              <p className="font-semibold text-gray-800">{conv.user.name}</p>
            </div>
          ))}
        </div>
          :
          <div className='text-center p-[16px]'>
            No patients yet.
          </div>
        }
      </div>
      <div className={`w-[100%] ${selectedConversationId ? '' : 'hidden'} md:block md:w-[70%] h-full bg-[#EFEAE2] overflow-y-scroll`}>
        {selectedConversationId && (
          <>
            <div className="flex bg-[#F0F2F5] items-center border-b h-[8vh] border-gray-300 p-4 sticky top-0">
              <button onClick={handleBackToConversations} className="mr-2 text-gray-800">
                ←
              </button>
              <img src="/profileIcon.svg" className="w-10 h-10 rounded-full bg-gray-300" alt="profile" />
              <p className="ml-3 font-semibold text-gray-800">
                {conversations.find((conv) => conv._id === selectedConversationId)?.user.name}
              </p>
            </div>
            <div>
            <div ref={messagesEndRef}  className='p-4 h-[84vh] overflow-y-scroll'>
                  {sortedMessages.map((msg) => (
                    <div
                      key={msg._id}
                      className={`my-2 p-3 rounded-lg max-w-xs ${msg.sender === 'USER' ? 'bg-white text-gray-800 self-start' : 'bg-green-500 text-white self-end ml-auto'}`}
                    >

                      {/* {msg.messageContent?.filename} {msg.messageContent?.text} */}
                      {msg.messageContent?.text} 
                      {msg.messageType==='IMAGE' && msg.messageContent?.url &&
                        <><img
                          src={msg.messageContent?.url}
                          alt="Image"
                          className="max-w-xs rounded w-full bg-white" /><div className='mt-2'>{msg.messageContent?.caption}</div></>
                            
                        } 
                          
                            {msg.messageType === 'FILE' &&
                            <div>
                            <a
                              href={msg.messageContent.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 underline"
                            >
                              {msg.messageContent.filename || "Document"}
                            </a>
                            {msg.messageContent?.caption &&
                            <div className='mt-2'>{msg.messageContent?.caption}</div>
                            }
                          </div>
                            }
                    </div>
                  ))}
                </div>
            <div className="border-t border-gray-300 bg-white h-[8vh] flex items-center p-4 sticky bottom-0">
            <label
                  htmlFor="file-upload"
                  className="cursor-pointer bg-green-500 text-white rounded px-4 py-2"
                >
                  +
                </label>
                <input
                  type="file"
                  accept=".pdf, .doc, .docx, image/*"
                  onChange={handleFileUpload}
                  className="hidden"
                  id="file-upload"
                />
                {file && filePreview && (
                  <div className="ml-2 text-gray-800">
                    <span>{file.name}</span>
                  </div>
                )}
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage();
                  }
                }}
                className="flex-1 p-2 ml-2 border rounded-lg border-gray-300 focus:outline-none"
              />
              <button
                onClick={handleSendMessage}
                className="ml-4 p-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send'}
              </button>
            </div>
            </div>
          </>
        )}
      </div>
    </div>
    </DefaultLayout>
  );
};

export default Chat;