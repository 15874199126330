import React from "react";
interface StatCardProps {
  title: string,
  value: number,
  isRevenue: boolean,
  oneTime?: number,
  premium?: number
}

const StatCard: React.FC<StatCardProps> = ({
  title,
  value,
  isRevenue,
  oneTime,
  premium
}) => {
  return (
    <div className={`rounded-[10px] ${isRevenue ? "bg-[#FCCE72]" : "bg-[#EFEFEA]"} grid p-4 text-[#315145] xs:flex`} style={{ gridTemplateColumns: '0.8fr 2fr' }}>
      <div className="w-full xs:hidden">
        <img className="w-full min-w-[29px] max-w-[42px] xs:hidden"
          src="/dashboardPatient.svg"
          alt="" ></img>
      </div>
      <div className={`flex ${isRevenue? "flex-row":"flex-col"} justify-between xs:flex-row xs:w-full`}>
        <div className="text-[18px] font-medium xs:max-w-[82px]">
          {title}
        </div>
        <div>
        <div className="font-bold text-[1.5em] xs:text-end">
          {value}
        </div>
        {isRevenue &&
            <div className="hidden xs:flex justify-between gap-[10px]">
              <div>
                <div className="text-[0.75em] font-medium flex flex-col justify-between">One Time</div>
                <div className="text-[1.2em] font-semibold">{oneTime}</div>
              </div>
              <div>
                <div className="text-[0.75em] font-medium flex flex-col justify-between">Premium</div>
                <div className="text-[1.2em] font-semibold">{premium}</div>
              </div>
            </div>
        }
        </div>
        
      </div>
      <div></div>
      {isRevenue &&
          <><div className="xs:hidden flex justify-between">
            <div>
              <div className="text-[0.75em] font-medium flex flex-col justify-between">One Time</div>
              <div className="text-[1.2em] font-semibold">{oneTime}</div>
            </div>
            <div>
              <div className="text-[0.75em] font-medium flex flex-col justify-between">Premium</div>
              <div className="text-[1.2em] font-semibold">{premium}</div>
            </div>
          </div>
          </>
        }
    </div>

  )
}


export default StatCard;
