import { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Box1 from "./Box1";
import Box5 from "./Box5";
import Footer from "../../components/Footer";
import { Dietician } from "../../model/DieticianDataModel";
import { useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import getFormatedDate from "../../utils/HandleDateFormate";
import { useSelector } from "react-redux";
import { loggedInUserSelector } from "../../redux/selectors/userSelector";
import axios from "axios";
import Counter from "./Counter";
import Section1 from "../../components/DoctorProfile/Section1";
import styled from "styled-components";
import { format } from 'date-fns';
const Desktop = () => {
  const { userId } = useParams();
  const [month, setMonth] = useState(2);
  const [selectedValue, setSelectedValue] = useState(
     "Premium Plan"
  );
  const scrollTo = useRef(null);
  const user = useSelector(loggedInUserSelector);
  console.log(user);
  const [dietician, setDietician] = useState<Dietician>();
  // const userId = `${searchParams.toString().split("id=")[1].split("&")[0]}`;
  console.log("ID", userId);
  const [showDescription, setShowDescription] = useState(selectedValue);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTime, setSelectedTime] = useState<string | null>();
  const [patientUserId, setPatientId] = useState("");
  const [filter, ] = useState<string>("All");

  const columnsPerPage = 4;
  useEffect(() => {
    if (userId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://backend.betternow.co.in/dietitian/${userId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log("My doctor", response.data);
          setDietician(response.data.data);
          console.log("diet", dietician);
        })
        .catch((error) => {
          console.log("Axios error", error);
        });
    }

    const token = localStorage.getItem("token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://backend.betternow.co.in/users/auth-status",
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Login status", response.data);
        if (response) {
          setPatientId(response.data.data._id);
          console.log("hi", patientUserId);
        }
      })
      .catch((error) => {
        console.log("Login status error", error);
      });
  }, []);
  useEffect(() => {
  }, [selectedValue, selectedTime, month]);
  if (!dietician) {
    return <></>;
  }
  
  const filteredData = dietician.specificAvailability.filter((availability: any) => {
    const currentDate = new Date(); // Get the current date in UTC
    const currentDateInIndia = new Date(currentDate.getTime()); // Adjust to IST
  
    const availabilityDate = new Date(availability.date); // Convert the availability date to a Date object
  
    // Reset the time part of current date to midnight for comparison
    currentDateInIndia.setHours(0, 0, 0, 0);
    availabilityDate.setHours(0, 0, 0, 0);
  
    // Calculate the date 7 days from the current date
    const next7DaysDate = new Date(currentDateInIndia); 
    next7DaysDate.setDate(currentDateInIndia.getDate() + 7);
  
    // Filter the availability within the next 7 days
    if (availabilityDate >= currentDateInIndia && availabilityDate <= next7DaysDate) {
      // If the availability date is today, filter out past time slots
      if (availabilityDate.getTime() === currentDateInIndia.getTime()) {
        const currentHour = currentDate.getHours();
        const currentMinute = currentDate.getMinutes();
  
        availability.slots = availability.slots.filter((slot: any) => {
          const [slotHour, slotMinute] = slot.from.split(':').map(Number);
          // Check if the slot time is in the future
          return (
            slotHour > currentHour || 
            (slotHour === currentHour && slotMinute > currentMinute)
          );
        });
      }
      return true;
    }
  
    return false;
  });
  
  const totalColumns = filteredData.length;
  const totalPages = Math.ceil(totalColumns / columnsPerPage);

  const handleNext = () => {
    console.log(filteredData)
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleTimeClick = (time: any, d: any) => {
    // setSelectedTime(`${d.date}-${time}`);
    setSelectedTime(time?._id);
    console.log("Selected time:", time);
  };
  
  
  // Slice the filtered data for pagination
  const displayedData = filteredData.slice(
    currentPage * columnsPerPage,
    (currentPage + 1) * columnsPerPage
  );
  // const displayedData = dietician.specificAvailability.slice(
  //   currentPage * columnsPerPage,
  //   (currentPage + 1) * columnsPerPage
  // );

  const handleRadio = (s: string) => {
    setSelectedValue(s);
    setShowDescription(s);
    if (s === "One time Consultation") {
      setMonth(0);
    } else {
      setMonth(2);
    }
  };
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
  
    const day = format(date, 'eee'); // Abbreviated day of the week (e.g., Sun, Mon, Tue, etc.)
    const dateMonth = format(date, 'd MMM'); // Day and month (e.g., 3 Sep)
  
    return { day, dateMonth };
  };
  return (
    <div className="bg-[#FFFFF5]">
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
        <Navbar
          backgroundColor="#FFFFF5"
          textColor="#315145"
          logo="/betterNowLogodark.png"
        />
      <div className="font-poppins">
      <StyledDiv className="bg-[#FFFFF5] mx-8 md:pt-[120px] overflow-x-hidden flex flex-col gap-[1.5rem] lg:gap-y-6 md:ml-12 md:mr-12 lg:ml-20 lg:mr-20">

       <Section1 expertise={dietician} />
       </StyledDiv>
      </div>

      <div className="w-[100vw] mb-10 mt-10 md:mt-20 flex md:flex-row flex-col items-center md:items-start md:gap-0 gap-y-6 justify-center md:justify-evenly">
        <div className="flex flex-col md:w-[45%] w-[86%] gap-y-8">
          <div>
            <Box1 />
          </div>
          <div>
            <div>
              <div className="bg-[#EFEFEA] rounded-xl font-poppins flex flex-col items-start pl-6 gap-y-2 pt-5 pb-5 xs:pl-[16px]">
                <div className="font-semibold text-[18px] flex">
                  <div>2.</div>
                  <div className="pl-5 xs:pl-[8px]">Select Membership</div>
                </div>

                {/* One-time Consultation */}
                <div className="mr-4 flex flex-col items-baseline pl-8 xs:pl-[19px]">
                  <div className="flex">
                  <input
                    type="radio"
                    name="Consultation"
                    checked={selectedValue === "One time Consultation"}
                    value="One time Consultation"
                    onChange={() => handleRadio("One time Consultation")}
                    className="w-5 h-5 bg-gray-100 border-[#484747]"
                  />
                  <label className="ms-2 text-[0.9rem] md:text-[1.125rem] text-[#505050] flex items-center gap-x-2 md:gap-x-4">
                    <div className="text-[14px]">
                      One time Consultation
                    </div>
                  </label>
                  </div>
                  {showDescription === "One time Consultation" && (
                    <div className="mt-2 p-4 text-[12px] bg-[#FFFFF5] border border-gray-300 rounded shadow-lg">
                      <li>Duration: One to one 45mints video/Audio consultation</li>
                      <li>Know more about your daily routine and queries</li>
                      <li>Discussion of your goals, concerns, and current lifestyle</li>
                      <li>Personalized recommendations tailored as per your need</li>
                      <li>No diet will be provided</li>
                      <li>No follow-up is included, but you can schedule additional consultations if needed</li>
                    </div>
                  )}
                </div>

                {/* Premium Plan */}
                <div className="mr-4 flex flex-col items-baseline pl-8 xs:pl-[19px]">
                <div className="flex justify-between">
                  <div className="flex">
                  <input
                    type="radio"
                    name="Consultation"
                    checked={selectedValue === "Premium Plan"}
                    value="Premium Plan"
                    onChange={() => handleRadio("Premium Plan")}
                    className="w-5 h-5 bg-gray-100 border-[#484747]"
                  />
                  <label className="ms-2 text-[0.9rem] md:text-[1.125rem] text-[#505050] flex items-center gap-x-2 md:gap-x-4">
                    <div className="text-[14px]">
                      Premium Plan
                    </div>
                  </label>
                  </div>
                    </div>
                    <div className="absolute right-[10%] md:right-[50%]">
                      {selectedValue !== "One time Consultation" && (
                        <Counter month={month} setMonth={setMonth} />
                      )}
                    </div>
                  {showDescription === "Premium Plan" && (
                    <div className="mt-2 p-4 text-[12px] bg-[#FFFFF5] border border-gray-300 rounded shadow-lg">
                      <li>45-minute video/audio consultation</li>
                      <li>Personalized diet plan every 3 weeks</li>
                      <li>Follow-up every 7 days for goal setting, diet adjustments, & motivation</li>
                      <li>Home remedies for acidity, bloating, and skin issues</li>
                      <li>Unlimited WhatsApp chat support for daily queries</li>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-[#EFEFEA] rounded-xl font-poppins flex flex-col items-start pl-6 gap-y-2 pt-5 pb-5 xs:pl-[16px]">
            <div className="font-semibold text-[18px] flex">
              <div>3.</div>
              <div className="pl-5">Select Date & Time</div>

            </div>
            {displayedData.length > 0 ? (
              <div className="flex flex-row items-baseline overflow-y-auto max-h-[350px] w-[100%]">
                <button
                  onClick={handleBack}
                  disabled={currentPage === 0}
                  className="sticky top-[-1px]"
                >
                  <img
                    className={`mt-6 max-w-3 cursor-pointer`}
                    src="/leftArrow.png"
                    alt="Go_Left"
                  />
                </button>
                <div className="flex justify-start w-[100%]">
                  {displayedData.map((d: any, index) => (
                    <div
                      key={index}
                      className="flex flex-col w-[16vw] md:w-[9vw] items-center gap-x-8 gap-y-4"
                    >
                      <div className="w-full text-[10px] text-center md:text-[12px] lg:text-[18px] sticky top-0 bg-[#EFEFEA]">
                        {formatDate(d.date).day}
                        <br />
                        {formatDate(d.date).dateMonth}
                      </div>
                      <div className="flex flex-col gap-y-2">
                        {d.slots.length === 0 ? (
                          <div className="text-[6.5px] md:text-[9px] lg:text-[14px] text-red-500 text-center">
                            No slots available
                          </div>
                        ) : (
                          d.slots
                            // Sort slots by the 'from' time
                            .sort((a: any, b: any) =>
                              new Date(`1970-01-01T${a.from}`).getTime() -
                              new Date(`1970-01-01T${b.from}`).getTime()
                            )
                            // Filter based on selected filter (AM/PM/All)
                            .filter((time: any) => {
                              const isAM = new Date(`1970-01-01T${time.from}`).getHours() < 12;
                              if (filter === "AM") return isAM;
                              if (filter === "PM") return !isAM;
                              return true; // If filter is 'All', return all slots
                            })
                            .map((time: any, i: number) => (
                              <div
                                key={i}
                                onClick={() => {
                                  if (time.isAvailable) {
                                    handleTimeClick(time, d);
                                  }
                                }}
                                className={`p-2 md:py-2 md:px-4 rounded-md md:rounded-xl text-[#EFEFEA] text-[9px] md:text-[9px] lg:text-[14px] ${time.isAvailable
                                    ? `${time._id}` === selectedTime
                                      ? "bg-[#1D7A4F]"
                                      : "bg-[#315145]"
                                    : "bg-gray-500 cursor-not-allowed"
                                  } ${!time.isAvailable ? "cursor-not-allowed" : "cursor-pointer"}`}
                              >
                                {new Date(`1970-01-01T${time.from}`)
                                  .toLocaleTimeString([], {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  })
                                  .replace("am", "AM")
                                  .replace("pm", "PM")}
                              </div>
                            ))
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  onClick={handleNext}
                  disabled={currentPage === totalPages - 1}
                  className="sticky top-[-1px]"
                >
                  <img className="mt-6 mr-8 max-w-3 ml-[8px]" src="/rightArrow.png" alt="Go_right" />
                </button>
              </div>
            ) : (
              <div className="pl-9 text-center text-red-500">
                No Slots Available
              </div>
            )}
            
          </div>
        </div>
        <div className="self-stretch w-[0.5px] md:block hidden bg-[#828282]"></div>
        <div className="md:w-[30%] w-[86%]">
          <div className="bg-[#EFEFEA] rounded-xl">
            <Box5
              dietitianId={dietician?.user?._id}
              months={month}
              dateTimeOfAppointment={getFormatedDate()}
              dieticianData={dietician}
              scrollTo={scrollTo}
              value={selectedValue}
              selectedSlot={selectedTime}
              userId={patientUserId}
            />
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Desktop;
const StyledDiv = styled.div`
  @media (max-width: 390px) {
    margin: 0px 1rem;
  }
`